import React, { useState } from "react";
import BaseModal from "../../Modal/BaseModal";
import Tracker from "./Tracker";
import InterviewDetailView from "../../CommonComponet/IntwerviewDetail/InterviewDetailView";
import InterviewStatus from "../../CommonComponet/IntwerviewDetail/interviewStatus";
import { useDispatch, useSelector } from "react-redux";
import { changeSubmissionData } from "../../../feature/store/SubmissionReduxStore";
import {
  ADD_INTERVIEW_DETAIL,
  CALL_TRACKER,
  INTERVIEW_DETAILS,
  VIEW_CALL_TRACKER,
  VIEW_SUBMISSION,
} from "../../../Utils/enum";
import { DATE_FORMATE, SUBMISSION_TH } from "../../../Utils/constant";
import {
  TableTh,
  TableTbody,
  Table,
  TableRow,
  TableTd,
  CustomTableContainer,
} from "../../../style/table.styled";
import moment from "moment";
import { CommonColor } from "../../../Utils/color";
import {
  CopyTextMainContainer,
  FlexContainer,
} from "../../../style/common.style";
import { CommonIcon } from "../../../Utils/CommonIcon";
import { DeleteIcon } from "../../../style/Icon.style";
import { getClientName } from "../../../Utils/utilitiFunction";
import { formatName } from "../../../Utils/validation";
import { ButtonName } from "../../../Utils/CommonString";
import {
  ButtonForChangePage,
  ButtonForChangePageContainer,
} from "../../../style/button.style";
import LoaderForTable from "../../CommonComponet/LoaderForTable";
import SubmissionFilter from "../../Status/SubmissionFilter";
import SearchLoader from "../../CommonComponet/SearchLoader";
import CustomizedTooltip from "../../CommonComponet/CustomizedTooltip";
import CopyTextComponent from "../../CommonComponet/CopyTextComponent";
import { onChangeSubmissionPageNumber } from "../../../feature/store/ReduxStore";

export default function SubmissionTable(props) {
  const dispatch = useDispatch();
  const selectedSubmissionData = useSelector(
    (state) => state.SubmissionReduxStore.selectedSubmissionData
  );
  const selected_status = useSelector(
    (state) => state.SubmissionReduxStore.selected_status
  );
  const submission_count = useSelector(
    (state) => state.SubmissionReduxStore.submission_count
  );
  const status_list = useSelector((state) => state.counter.submissionData);
  const search_result = useSelector(
    (state) => state.SubmissionReduxStore.search_result
  );
  const table_list = useSelector(
    (state) => state.SubmissionReduxStore.table_list
  );
  const temp_partner_data = useSelector(
    (state) => state.SubmissionReduxStore.temp_partner_data
  );
  const temp_client_data = useSelector(
    (state) => state.SubmissionReduxStore.temp_client_data
  );
  const submissionPagenumber = useSelector(
    (state) => state.counter.submissionPagenumber
  );
  const searchText = useSelector((state) => state.counter.searchText);
  const isVisible = useSelector((state) => state.counter.is_visible);
  const [modalStatus, setModalStatus] = useState();
  const [isopenModal, setisOpenModal] = useState(false);
  const [submission_data, setsubmission_data] = useState();
  const [isLoading, setisLoading] = useState(false);
  const access_data_by_user_id = useSelector(
    (state) => state.counter.access_data_by_user_id
  );
  const status_data = useSelector(
    (state) => state.SubmissionReduxStore.status_list
  );
  const temp_recruiter_list = useSelector(
    (state) => state.JobReduxStore.temp_recruiter_list
  );
  const filterStatusObj = useSelector(
    (state) => state.SubmissionReduxStore.filter_data
  );

  // const [submissionData, setSubmissionData] = useState([]);

  function onViewModal(modalSt, data) {
    setsubmission_data(data);
    setisOpenModal(true);
    setModalStatus(modalSt);
    dispatch(changeSubmissionData(data));
  }

  function getColor(e) {
    var inActiveColor = {
      background: CommonColor.white,
      color: CommonColor.primary_color,
    };
    if (e.interviewData === undefined) {
      return inActiveColor;
    }
    if (e.interviewData.data !== undefined) {
      var interViewDate = moment(e?.interviewData?.data?.date).format(
        DATE_FORMATE
      );
      var currentDate = moment().format(DATE_FORMATE);
      if (interViewDate === currentDate) {
        var color = {
          background: CommonColor.bg_yellow,
          color: CommonColor.primary_color,
        };
        return color;
      }
      // else if(interViewDate ===moment(currentDate, "DD-MM-YYYY").add(1, 'days')._i){
      //   var color_1 = {
      //     background: CommonColor.bg_green,
      //     color: CommonColor.green,
      //   };
      //   return color_1;
      // }
    }
    return inActiveColor;
  }

  return (
    <>
      {props.isLoading && props.submissionList.length === 0 ? (
        <LoaderForTable />
      ) : (
        <></>
      )}
      <BaseModal
        isFaq={modalStatus === CALL_TRACKER}
        isLoading={isLoading}
        mystyle={{ width: window.innerWidth > 500 ? "50%" : "90%" }}
        open={isopenModal}
        formTitle={
          modalStatus === INTERVIEW_DETAILS ||
          modalStatus === ADD_INTERVIEW_DETAIL
            ? INTERVIEW_DETAILS
            : CALL_TRACKER
        }
        handleClose={() => setisOpenModal(!isopenModal)}
        editClose={() =>
          onViewModal(
            modalStatus === INTERVIEW_DETAILS
              ? ADD_INTERVIEW_DETAIL
              : VIEW_CALL_TRACKER,
            selectedSubmissionData
          )
        }
      >
        {modalStatus === INTERVIEW_DETAILS ||
        modalStatus === ADD_INTERVIEW_DETAIL ? (
          <InterviewStatus
            handleClose={() => setisOpenModal(!isopenModal)}
            isLoading={(e) => setisLoading(e)}
            modalStatus={modalStatus}
            selectedData={selectedSubmissionData}
          />
        ) : (
          <Tracker
            submission_id={submission_data?._id}
            isLoading={(e) => setisLoading(e)}
            submission_data={submission_data}
            selectedData={selectedSubmissionData}
            handleClose={() => setisOpenModal(!isopenModal)}
            modalStatus={modalStatus}
          />
        )}
      </BaseModal>
      <CustomTableContainer height="85vh">
        <Table>
          <TableTbody>
            <TableRow>
              <TableTh width={"2%"}>No.</TableTh>
              <TableTh
                width={"16%"}
                isVisible={!table_list.includes("applicant")}
              >
                Applicant
              </TableTh>
              <TableTh
                width={"25%"}
                isVisible={!table_list.includes("position_title")}
              >
                Position title
              </TableTh>
              <TableTh width={"10%"} isVisible={!table_list.includes("job_id")}>
                Job ID
              </TableTh>
              <TableTh width={"6%"} isVisible={!table_list.includes("partner")}>
                <SubmissionFilter
                  selectedValue={filterStatusObj.partner_id}
                  is_active={filterStatusObj.partner_id !== "123"}
                  list={temp_partner_data}
                  name={"Partner"}
                />
              </TableTh>
              <TableTh width={"6%"} isVisible={!table_list.includes("client")}>
                <SubmissionFilter
                  selectedValue={filterStatusObj.client_id}
                  is_active={filterStatusObj.client_id !== "123"}
                  list={temp_client_data}
                  name={"Client"}
                />
              </TableTh>
              <TableTh
                isVisible={!isVisible || !table_list.includes("recruiter")}
              >
                <SubmissionFilter
                  selectedValue={filterStatusObj.recruiter_id}
                  is_active={filterStatusObj.recruiter_id !== "123"}
                  list={temp_recruiter_list}
                  name={"Recruiter"}
                />
              </TableTh>
              <TableTh isVisible={!table_list.includes("updated_at")}>
                Updated At
              </TableTh>
              <TableTh isVisible={!table_list.includes("status")}>
                <SubmissionFilter
                  is_active={filterStatusObj.status !== "ALL"}
                  list={status_data}
                  selectedValue={filterStatusObj.status}
                  name={"Status"}
                />
              </TableTh>
              <TableTh width={"13%"}></TableTh>
            </TableRow>
            {(searchText.length !== 0 ? search_result : status_list).map(
              (singleData, index) => {
                const { client, parentJob, partner, recruiter } = singleData;
                if (
                  selected_status === singleData?.status ||
                  selected_status === "All"
                )
                  return (
                    <TableRow
                      // style={{
                      //   background: getColor(singleData).background,
                      //   color: getColor(singleData).color,
                      // }}
                      // isActiveDate={
                      //   getColor(singleData).background ===
                      //   CommonColor.bg_yellow
                      // }
                      onDoubleClick={() =>
                        props.onViewSubmission(VIEW_SUBMISSION, singleData)
                      }
                      key={index}
                      onClick={() =>
                        window.innerWidth < 500
                          ? props.onViewSubmission(VIEW_SUBMISSION, singleData)
                          : null
                      }
                    >
                      <TableTd
                        ListLength={SUBMISSION_TH.length}
                        style={{ paddingLeft: 10 }}
                      >
                        {index + 1}
                      </TableTd>
                      <TableTd
                        isVisible={!table_list.includes("applicant")}
                        ListLength={SUBMISSION_TH.length}
                      >
                        <CopyTextMainContainer>
                          <CustomizedTooltip
                            name={formatName(singleData?.full_name)}
                            title={formatName(singleData?.full_name)}
                          />
                          <CopyTextComponent
                            value={formatName(singleData?.full_name)}
                            id={"applicant"}
                          />
                        </CopyTextMainContainer>
                      </TableTd>
                      <TableTd
                        isVisible={!table_list.includes("position_title")}
                        ListLength={SUBMISSION_TH.length}
                      >
                        <CustomizedTooltip
                          name={parentJob?.position_title}
                          title={parentJob?.position_title}
                        />
                      </TableTd>
                      <TableTd
                        isVisible={!table_list.includes("job_id")}
                        ListLength={SUBMISSION_TH.length}
                      >
                        {parentJob?.client_position_id === "" ? (
                          "NA"
                        ) : (
                          <CopyTextMainContainer>
                            <CustomizedTooltip
                              name={parentJob?.client_position_id}
                              title={parentJob?.client_position_id}
                            />
                            <CopyTextComponent
                              value={parentJob?.client_position_id}
                              id={"job_id"}
                            />
                          </CopyTextMainContainer>
                        )}
                      </TableTd>
                      <TableTd
                        isVisible={!table_list.includes("partner")}
                        ListLength={SUBMISSION_TH.length}
                      >
                        <CustomizedTooltip
                          name={getClientName(partner?.name)}
                          title={getClientName(partner?.name)}
                        />
                      </TableTd>
                      <TableTd
                        isVisible={!table_list.includes("client")}
                        ListLength={SUBMISSION_TH.length}
                      >
                        <CustomizedTooltip
                          name={getClientName(client?.name)}
                          title={getClientName(client?.name)}
                        />
                      </TableTd>
                      <TableTd
                        isVisible={
                          !isVisible || !table_list.includes("recruiter")
                        }
                        ListLength={SUBMISSION_TH.length}
                      >
                        {recruiter?.name?.split(" ")[0]}
                      </TableTd>
                      <TableTd
                        isVisible={!table_list.includes("updated_at")}
                        style={{ width: 90 }}
                        ListLength={SUBMISSION_TH.length}
                      >
                        {moment(singleData?._created_at).format(DATE_FORMATE)}
                      </TableTd>
                      <TableTd
                        isVisible={!table_list.includes("status")}
                        ListLength={SUBMISSION_TH.length}
                      >
                        <CustomizedTooltip
                          name={
                            singleData?.status
                              ? formatName(singleData?.status)
                              : ""
                          }
                          title={
                            singleData?.status
                              ? formatName(singleData?.status)
                              : ""
                          }
                        />
                      </TableTd>
                      <TableTd
                        onClick={(e) => e.stopPropagation()}
                        ListLength={SUBMISSION_TH.length}
                      >
                        <FlexContainer>
                          <InterviewDetailView
                            onClickInterviewDetail={(e) =>
                              onViewModal(INTERVIEW_DETAILS, singleData)
                            }
                            onClickSubmissionTracker={(e) =>
                              onViewModal(CALL_TRACKER, singleData)
                            }
                            data={singleData}
                          />
                          {access_data_by_user_id.includes(
                            "submission_delete"
                          ) ? (
                            <DeleteIcon
                              isDahsboard={props.isDahsboard}
                              isVisible={isVisible}
                              icon={CommonIcon.delete_outline}
                              onClick={() => props.onDelete(singleData)}
                            />
                          ) : (
                            <></>
                          )}
                        </FlexContainer>
                      </TableTd>
                    </TableRow>
                  );
              }
            )}
          </TableTbody>
        </Table>
        {/* <Container
          isLoading={props.isLoading || is_search_loading}
          isVisible={getVisibility()}
        /> */}
        {(searchText.length !== 0 ? search_result : status_list).length % 20 !==
          0 ||
        submission_count === 0 ||
        props.isLoading ? (
          <></>
        ) : (
          <ButtonForChangePageContainer>
            <ButtonForChangePage
              onClick={() =>
                dispatch(onChangeSubmissionPageNumber(submissionPagenumber + 1))
              }
            >
              {ButtonName.load_more}
            </ButtonForChangePage>
          </ButtonForChangePageContainer>
        )}
        {props.isLoading && props.submissionList.length !== 0 ? (
          <SearchLoader />
        ) : (
          <></>
        )}
      </CustomTableContainer>
    </>
  );
}
