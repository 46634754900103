import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { placeholder_name_without_id } from "../../Utils/mockData";

export default function SelectTextFields(props) {
  const [inputValue, setInputValue] = useState(
    props.value ? props.getOptionLabel(props.value) : ""
  );
  function getValue(data) {
    if (data === null) {
      return;
    }
    if (placeholder_name_without_id.includes(props.placeholder)) {
      props.onChange(data);
      return;
    }
    let filteredData = props.options.filter(
      (singleData) => props.getOptionLabel(singleData) === data
    );
    props.onChange(filteredData[0]);
  }
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      const highlightedOption = props.options.find(
        (option) => props.getOptionLabel(option) === inputValue
      );
      if (highlightedOption === null) {
        return;
      }
      if (highlightedOption) {
        getValue(highlightedOption);
      }
    }
  };
  return (
    <div style={props.style}>
      <label>{props.placeholder}</label>
      <Autocomplete
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        onKeyDown={handleKeyDown}
        disablePortal
        onChange={(event, newValue) => {
          setInputValue(props.getOptionLabel(newValue));
          getValue(props.getOptionLabel(newValue));
        }}
        // onBlur={(e) => console.log(e)}
        value={props?.value}
        options={props?.options}
        getOptionLabel={props.getOptionLabel}
        onBlur={props.onBlur}
        renderInput={(params) => <TextField {...params} />}
      />
    </div>
  );
}
