import React, { useEffect, useRef, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import {
  getClientName,
  getRecruiters,
  jobStatusColor,
} from "../../../Utils/utilitiFunction";
import { VIEW_JOBS } from "../../../Utils/enum";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import {
  CustomTableContainer,
  TableTh,
  TableTd,
  TableRow,
} from "../../../style/table.styled";
import { DATE_FORMATE, JOB_FOR_APPLICANT_TH } from "../../../Utils/constant";
import moment from "moment";
import { CommonString } from "../../../Utils/CommonString";
import { CommonIcon } from "../../../Utils/CommonIcon";
import { CommonColor } from "../../../Utils/color";
import JobController from "../../../controller/JobController";
import { checkNullString } from "../../../Utils/validation";
import { setSelectedJobForLockApplicant } from "../../../feature/store/JobReduxStore";
import { changeJobListForApplicant } from "../../../feature/store/ApplicantStore";
import {
  Input,
  SearchButton,
  SearchInputForm,
} from "../../../style/applicant.style";
import JobDataShimmer from "../../Applicant/JobDataShimmer";
import EmptyBlock from "../../Applicant/EmptyBlock";
import KeyboardIcon from "../../../assets/images/key-enter2.png";
import emptyBox from "../../../assets/images/empty-box.png";
import { Divider } from "@mui/material";
import ApplicantContoller from "../../../controller/ApplicantContoller";
import Container from "../../CommonComponet/Container";
import CustomizedTooltip from "../../CommonComponet/CustomizedTooltip";

export default function JobTableForViewApplicant(props) {
  const listInnerRef = useRef();
  const dispatch = useDispatch();
  const jobController = new JobController();
  const applicantController = new ApplicantContoller();
  const job_list_for_applicant = useSelector(
    (state) => state.ApplicantStore.job_list_for_applicant
  );
  const [searchResult, setSearchResult] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const selectedJobForLockApplicant = useSelector(
    (state) => state.JobReduxStore.selectedJobForLockApplicant
  );
  const is_loading_job = useSelector(
    (state) => state.ApplicantStore.is_loading_job
  );
  const selected_recruiter_id = useSelector(
    (state) => state.JobReduxStore.selected_recruiter_id
  );
  function getDate(date) {
    if (date === "") return "open";
    return moment(date).format(DATE_FORMATE);
  }
  useEffect(() => {
    applicantController.getJobDataList(1, selected_recruiter_id);
  }, [selected_recruiter_id]);

  const onScroll = () => {
    // if (listInnerRef.current) {
    //   const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
    //   if (scrollTop + clientHeight + 1 === scrollHeight) {
    //     applicantController.getJobDataList(pageNumber + 1);
    //   }
    // }
  };

  async function onSearchByClientId(e) {
    setIsLoading(true);
    e.preventDefault();
    var sentData = {
      search_query: searchQuery.trim(),
    };
    var res = await jobController.searchByClientID(sentData);
    dispatch(changeJobListForApplicant(res.data.data));
    setSearchResult(res.data.data);
    setIsLoading(false);
    setIsSearchActive(false);
  }

  return (
    <>
      <SearchInputForm onSubmit={(e) => onSearchByClientId(e)}>
        <Input
          type="text"
          placeholder={CommonString.searchByPositonNameandID}
          value={searchQuery}
          onChange={(e) => {
            if (e.target.value.length == 0) {
              setSearchResult([]);
            }
            setIsSearchActive(true);
            setSearchQuery(e.target.value);
          }}
        />
        <SearchButton onClick={(e) => onSearchByClientId(e)} type="submit">
          search
        </SearchButton>
      </SearchInputForm>
      <CustomTableContainer
        ref={listInnerRef}
        height={CommonString.job_table_height}
        onScroll={onScroll}
      >
        {job_list_for_applicant.length == 0 && searchQuery.length > 0 ? (
          isSearchActive ? (
            <EmptyBlock
              title="Press enter or search button."
              image={KeyboardIcon}
            />
          ) : (
            <EmptyBlock title="No Data found" image={emptyBox} />
          )
        ) : job_list_for_applicant.length == 0 && is_loading_job ? (
          <JobDataShimmer />
        ) : (
          <>
            <TableRow>
              {JOB_FOR_APPLICANT_TH.map((singleTH) => (
                <TableTh>
                  <span style={{ fontSize: 11 }}>{singleTH}</span>
                </TableTh>
              ))}
            </TableRow>
            {job_list_for_applicant.map((data, index) => (
              <TableRow
                onDoubleClick={() => props.onViewModal(VIEW_JOBS, data)}
                onClick={() =>
                  window.innerWidth < 500
                    ? props.onViewModal(VIEW_JOBS, data)
                    : null
                }
                key={index}
              >
                <TableTd>
                  <Checkbox
                    size="small"
                    defaultChecked={
                      selectedJobForLockApplicant != null &&
                      selectedJobForLockApplicant._id === data._id
                    }
                    checked={
                      selectedJobForLockApplicant != null &&
                      selectedJobForLockApplicant._id === data._id
                    }
                    onClick={(e) => e.stopPropagation()}
                    onChange={() => {
                      dispatch(setSelectedJobForLockApplicant(data));
                    }}
                    style={{ color: CommonColor.primary_color, padding: 0 }}
                  />
                </TableTd>
                <TableTd
                  style={{
                    color:
                      jobStatusColor[
                        data ? data.status : CommonString.all_uppercase
                      ].color,
                  }}
                >
                  <CustomizedTooltip
                    name={checkNullString(data.job_detail.client_position_id)}
                    title={checkNullString(data.job_detail.client_position_id)}
                  />
                </TableTd>
                <TableTd>
                  <CustomizedTooltip
                    name={getClientName(data.partner.name)}
                    title={getClientName(data.partner.name)}
                  />
                </TableTd>
                <TableTd>
                  <CustomizedTooltip
                    name={getClientName(data.job_detail.client.name)}
                    title={getClientName(data.job_detail.client.name)}
                  />
                </TableTd>
                {/* <TableTd>
                  <CustomizedTooltip
                    name={
                      data.job_detail.department === ""
                        ? CommonString.na
                        : getClientName(data.job_detail.department)
                    }
                    title={
                      data.job_detail.department === ""
                        ? CommonString.na
                        : data.job_detail.department
                    }
                  />
                </TableTd> */}
                <TableTd>
                  <CustomizedTooltip
                    name={data.job_detail.position_title}
                    title={data.job_detail.position_title}
                  />
                </TableTd>
                <TableTd isCenter={true}>{data.no_of_submission}</TableTd>
                <TableTd isCenter={true}>
                  {checkNullString(data.max_submission)}
                </TableTd>
                <TableTd>{getDate(data.due_date)}</TableTd>
                <TableTd>
                  {data.recruiter === null
                    ? "Open"
                    : data.recruiter.name.split(" ")[0]}
                  {/* {getRecruiters(data)} */}
                </TableTd>
                <TableTd
                  onClick={(e) => {
                    e.stopPropagation();
                    props.onViewModal(VIEW_JOBS, data);
                  }}
                >
                  <Icon icon={CommonIcon.dash_icon} />
                </TableTd>
              </TableRow>
            ))}
          </>
        )}
      </CustomTableContainer>
      <Container
        isLoading={is_loading_job}
        isVisible={!is_loading_job && job_list_for_applicant.length == 0}
      />
    </>
  );
}
