import React, { useEffect, useState } from "react";
import CheckBox from "../basic/CheckBox";
import { useDispatch, useSelector } from "react-redux";
import {
  changeInitialTableList,
  changeTableList,
  removeFromTableList,
} from "../../feature/store/JobReduxStore";
import Menu from "@mui/material/Menu";
import { CommonIcon } from "../../Utils/CommonIcon";
import { FieldFilterIcon, FlexContainer } from "../../style/common.style";
const ITEM_HEIGHT = 48;
var card_list = {
  all: true,
  status: true,
  due_date: true,
  job_title: true,
  job_id: true,
  client: true,
  max_sub: true,
  sub_made: true,
  partner: true,
  recruiter: true,
};
var card_list_with_value = [
  "all",
  "status",
  "due_date",
  "job_title",
  "job_id",
  "client",
  "max_sub",
  "sub_made",
  "partner",
  "recruiter",
];
export default function CustomizeTableFiled() {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [formData, setFormData] = useState(card_list);
  const table_list = useSelector((state) => state.JobReduxStore.table_list);

  useEffect(() => {
    dispatch(changeInitialTableList(table_list));
  }, []);

  function handleInputChange(event) {
    const { name, checked } = event.target;
    const hasFalse = Object.values({ ...formData, [name]: checked }).some(
      (value) => value === false
    );
    if (hasFalse) {
      setFormData({ ...formData, all: false });
      dispatch(removeFromTableList("all"));
    } else {
      dispatch(changeInitialTableList(card_list_with_value));
    }
    if (name === "all" && !checked) {
      setFormData({
        all: false,
        status: false,
        due_date: false,
        job_title: false,
        job_id: false,
        client: false,
        max_sub: false,
        sub_made: false,
        partner: false,
        recruiter: false,
      });
      dispatch(changeInitialTableList([]));
      return;
    }
    if (name === "all" && checked) {
      setFormData(card_list);
      dispatch(changeInitialTableList(card_list_with_value));
      return;
    }
    setFormData(
      hasFalse
        ? { ...formData, [name]: checked, all: false }
        : { ...formData, [name]: checked }
    );
    if (checked) {
      dispatch(changeTableList(name));
      return;
    }
    dispatch(removeFromTableList(name));
  }

  return (
    <>
      <FlexContainer>
        <FieldFilterIcon
          onClick={(event) => setAnchorEl(event.currentTarget)}
          icon={CommonIcon.list}
        />
      </FlexContainer>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={(event) => setAnchorEl(null)}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            padding: "0 10px",
          },
        }}
      >
        <>
          <CheckBox
            onClick={handleInputChange}
            value={formData.all}
            label={"All"}
            name="all"
          />
          <CheckBox
            onClick={handleInputChange}
            value={formData.status}
            label={"Status"}
            name="status"
          />
          <CheckBox
            onClick={handleInputChange}
            value={formData.due_date}
            label={"Due Date"}
            name="due_date"
          />
          <CheckBox
            onClick={handleInputChange}
            value={formData.job_title}
            label={"Job Title"}
            name="job_title"
          />
          <CheckBox
            onClick={handleInputChange}
            value={formData.job_id}
            label={"Job ID"}
            name="job_id"
          />
          <CheckBox
            onClick={handleInputChange}
            value={formData.client}
            label={"Client"}
            name="client"
          />
          <CheckBox
            onClick={handleInputChange}
            value={formData.max_sub}
            label={"Max Sub"}
            name="max_sub"
          />
          <CheckBox
            onClick={handleInputChange}
            value={formData.sub_made}
            label={"Sub Made"}
            name="sub_made"
          />
          <CheckBox
            onClick={handleInputChange}
            value={formData.partner}
            label={"Partner"}
            name="partner"
          />
          <CheckBox
            onClick={handleInputChange}
            value={formData.recruiter}
            label={"Recruiter"}
            name="recruiter"
          />
        </>
      </Menu>
    </>
  );
}
