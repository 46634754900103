import * as React from "react";
import { Box, Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { changeIsOpenModal } from "../../feature/store/ApplicantStore";
import { ModalTitle } from "react-bootstrap";
import { TitleContainer } from "../../style/job.style";
import {
  FormTitleContainer,
  ModalDataContainer,
  ModalTitleSpan,
} from "../../style/common.style";
import CustomTooltip from "../CommonComponet/Tooltip";
import { CommonString, CommonTitle } from "../../Utils/CommonString";
import { ModalIcon } from "../../style/Icon.style";
import { CommonIcon } from "../../Utils/CommonIcon";
import { ADD_JOBS, VIEW_JOBS } from "../../Utils/enum";
import FullScreenLoader from "../CommonComponet/FullScreenLoader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 3,
  zIndex: 1,
};

export default function CommonBaseModal(props) {
  const dispatch = useDispatch();
  const is_open_modal = useSelector(
    (state) => state.ApplicantStore.is_open_modal
  );
  const button_name = useSelector((state) => state.ApplicantStore.button_name);
  const selectedData = useSelector(
    (state) => state.JobReduxStore.selected_data
  );
  const isLoadingInModal = useSelector(
    (state) => state.counter.isLoadingInModal
  );
  function getTitle() {
    if (button_name === VIEW_JOBS) {
      let id =
        selectedData.job_detail.client_position_id == null ||
        selectedData.job_detail.client_position_id == undefined ||
        selectedData.job_detail.client_position_id == ""
          ? ""
          : `- ${selectedData.job_detail.client_position_id}`;
      return `${selectedData.job_detail.position_title}${id}`;
    }
    return button_name;
  }
  return (
    <Modal
      style={{
        zIndex: props.zIndex ? props.zIndex : 0,
      }}
      open={is_open_modal}
    >
      <Box
        sx={style}
        style={{
          width: props.size === "medium" ? "60vw" : "100vw",
          height: props.size === "medium" ? "90vh" : "100vh",
          overflow: isLoadingInModal ? "hidden" : "auto",
          overflowX: "hidden",
          transitionDelay: "8s",
          // borderRadius: 10,
          // boxShadow:
          //   "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        }}
      >
        {isLoadingInModal ? (
          <FullScreenLoader
            isUpdate={props.isUpdate}
            style={{
              width: props.isBig ? "100%" : 500,
              height: props.isBig ? "100%" : "auto",
              ...props.mystyle,
            }}
          />
        ) : (
          <div />
        )}
        <FormTitleContainer>
          <TitleContainer is_padding={true}>
            <ModalTitle>
              {getTitle()}
              <ModalTitleSpan></ModalTitleSpan>
            </ModalTitle>
            <CustomTooltip
              placement={CommonString.top_placement}
              title={CommonTitle.close}
              name={
                <ModalIcon
                  icon={CommonIcon.modal_close}
                  onClick={() => {
                    button_name === ADD_JOBS
                      ? props.handleClose()
                      : dispatch(changeIsOpenModal(false));
                  }}
                />
              }
            />
          </TitleContainer>
        </FormTitleContainer>
        <ModalDataContainer>
          {props.children === undefined ? <p>no data</p> : props.children}
        </ModalDataContainer>
      </Box>
    </Modal>
  );
}
