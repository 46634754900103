import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

export default function CustomDatePicker(props) {
  return (
    <div
      className="date"
      style={{
        marginTop: (props) => (props.margin !== undefined ? props.margin : 10),
        padding: (props) => (props.margin ? props.margin : 0),
      }}
    >
      <label>{props.label}</label>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          size="small"
          minDate={props.minDate}
          InputProps={{ style: { fontSize: 12 } }}
          InputLabelProps={{ style: { fontSize: 12 } }}
          // label={props.label}
          value={props.value}
          onChange={props.onChange}
          renderInput={(params) => (
            <TextField
              InputProps={{ style: { fontSize: 12 } }}
              InputLabelProps={{
                style: {
                  fontSize: 12,
                  paddingLeft: (props) => (props.margin ? 0 : 10),
                },
              }}
              size="small"
              {...params}
            />
          )}
        />
      </LocalizationProvider>
    </div>
  );
}
