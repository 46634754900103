import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import LoadingBar from "react-top-loading-bar";
import ApplicantData from "./view_component/ApplicantData";
import {
  changeSelectedRecruiterId,
  changeTempJobList,
  changeTempRecruiterList,
} from "../../feature/store/JobReduxStore";
import RecruiterController from "../../controller/RecruiterController";
import { ApplicantViewContainer } from "../../style/common.style";

import { CommonStringForLocalStorage } from "../../Utils/CommonString";
import { CommonColor } from "../../Utils/color";
import BaseModal from "../Modal/BaseModal";
import { VIEW_SUBMISSION } from "../../Utils/enum";
import SubmissionViewDetails from "./SubmissionViewDetails";

export default function ApplicantDataDetails(props) {
  const dispatch = useDispatch();
  const recruiterController = new RecruiterController();
  const jobData = useSelector((state) => state.counter.jobData);
  const applicant = useSelector(
    (state) => state.ApplicantStore.selectedApplicant
  );
  const [progress, setProgress] = useState(0);
  const [modalStatus, setModalStatus] = useState();
  const [isopenModal, setisOpenModal] = useState(false);
  const [selectedData, setselectedData] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    var data = await recruiterController.getRecruiters();
    const temprecruiterlist = [];
    temprecruiterlist.push(
      { name: "All", _id: "123", is_recruiter: "true" },
      ...data
    );
    dispatch(changeTempRecruiterList(temprecruiterlist));
    var user = JSON.parse(
      localStorage.getItem(CommonStringForLocalStorage.user)
    );
    dispatch(changeSelectedRecruiterId(user.user._id));
    dispatch(changeTempJobList(jobData));
  }
  function onViewModal(modalSt, data) {
    setisOpenModal(!isopenModal);
    setModalStatus(modalSt);
    setselectedData(data);
  }

  return (
    <>
      <BaseModal
        isBig={true}
        open={isopenModal}
        formTitle={VIEW_SUBMISSION}
        handleClose={() => setisOpenModal(!isopenModal)}
      >
        <SubmissionViewDetails data={selectedData} />
      </BaseModal>
      <LoadingBar
        style={{ height: 4 }}
        color={CommonColor.border}
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <ApplicantViewContainer>
        <ApplicantData
          onViewModal={onViewModal}
          isLockView={true}
          data={applicant}
        />
      </ApplicantViewContainer>
    </>
  );
}
