import * as React from "react";
import { Box, Modal } from "@mui/material";
import {
  ClearFilterText,
  FlexContainer,
  ModalTitle,
  TableNoDataContainer,
} from "../../style/common.style";
import { TitleContainer } from "../../style/job.style";
import CustomTooltip from "../CommonComponet/Tooltip";
import {
  CommonString,
  CommonStringForLocalStorage,
  CommonTitle,
} from "../../Utils/CommonString";
import { Icon } from "@iconify/react";
import { CommonIcon } from "../../Utils/CommonIcon";
import {
  Table,
  TableContainerForCountData,
  TableRow,
  TableTd,
  TableTh,
} from "../../style/table.styled";
import moment from "moment";
import { DATE_FORMATE, SUBMISSION_TH } from "../../Utils/constant";
import { formatName } from "../../Utils/validation";
import InterviewDetailView from "../CommonComponet/IntwerviewDetail/InterviewDetailView";
import {
  convertTo12HourFormat,
  getClientName,
  onCheckLevelAccessibility,
} from "../../Utils/utilitiFunction";
import { INTERVIEW_DETAILS, VIEW_SUBMISSION } from "../../Utils/enum";
import LoaderForTable from "../CommonComponet/LoaderForTable";
import NoData from "../../assets/images/nodata.png";
import FilterForJob from "../jobs/FilterForJob";
import { useDispatch, useSelector } from "react-redux";
import SubmissionFilter from "../Status/SubmissionFilter";
import { onChangeFilterStatusObject } from "../../feature/store/ReduxStore";
import { changeFilterData } from "../../feature/store/SubmissionReduxStore";
import CustomizedTooltip from "../CommonComponet/CustomizedTooltip";
import OrderFilter from "../CommonComponet/OrderFilter";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100vw",
  height: "100vh",
  bgcolor: "background.paper",
  p: 4,
};

export default function CountDataDetails(props) {
  const dispatch = useDispatch();
  let user = localStorage.getItem(CommonStringForLocalStorage.user);
  const filterStatusObj = useSelector(
    (state) => state.SubmissionReduxStore.filter_data
  );
  var userDetails = JSON.parse(user).user;
  const temp_recruiter_list = useSelector(
    (state) => state.JobReduxStore.temp_recruiter_list
  );
  const temp_partner_data = useSelector(
    (state) => state.SubmissionReduxStore.temp_partner_data
  );
  const temp_client_data = useSelector(
    (state) => state.SubmissionReduxStore.temp_client_data
  );
  var recruiter_id = filterStatusObj.recruiter_id;
  var client_id = filterStatusObj.client_id;
  var partner_id = filterStatusObj.partner_id;
  const [selectedOrder, setSelectedOrder] = React.useState("DESC");
  React.useEffect(() => {
    onFilterData();
  }, [filterStatusObj]);
  function getData() {
    if (onCheckLevelAccessibility()) {
      return props.data;
    } else {
      let filteredData = props.data?.filter(
        (data) => data?.recruiter_id === userDetails?._id
      );
      return filteredData;
    }
  }
  async function getAscData(data) {
    if (selectedOrder === "DESC") {
      var AscendingData = await data
        .sort((a, b) => {
          return new Date(getDate(a)) - new Date(getDate(b));
        })
        .reverse();
      return AscendingData;
    }
    var descData = await data.sort((a, b) => {
      return new Date(getDate(a)) - new Date(getDate(b));
    });
    return descData;
  }
  function onFilterData() {
    var data = getData();
    if (data === undefined) return;
    // console.log("data", getDate(data));
    getAscData(data);
    if (recruiter_id === "123" && client_id === "123" && partner_id === "123") {
      return data;
    }
    if (recruiter_id !== "123" && client_id === "123" && partner_id === "123") {
      return data.filter(
        (singleData) => singleData.recruiter._id === recruiter_id
      );
    }
    if (recruiter_id === "123" && client_id !== "123" && partner_id === "123") {
      return data.filter(
        (singleData) => singleData.client._id === filterStatusObj.client_id
      );
    }
    if (recruiter_id === "123" && client_id === "123" && partner_id !== "123") {
      return data.filter(
        (singleData) => singleData.partner._id === filterStatusObj.partner_id
      );
    }
    if (recruiter_id !== "123" && client_id !== "123" && partner_id === "123") {
      let client_filter_data = data.filter(
        (singleData) => singleData.client._id === filterStatusObj.client_id
      );
      return client_filter_data.filter(
        (singleData) => singleData.recruiter._id === recruiter_id
      );
    }
    if (recruiter_id !== "123" && client_id === "123" && partner_id !== "123") {
      let partner_filter_data = data.filter(
        (singleData) => singleData.partner._id === filterStatusObj.partner_id
      );
      return partner_filter_data.filter(
        (singleData) => singleData.recruiter._id === recruiter_id
      );
    }
    if (recruiter_id === "123" && client_id !== "123" && partner_id !== "123") {
      let partner_filter_data = data.filter(
        (singleData) => singleData.partner._id === filterStatusObj.partner_id
      );
      return partner_filter_data.filter(
        (singleData) => singleData.client._id === client_id
      );
    }
    if (recruiter_id !== "123" && client_id !== "123" && partner_id !== "123") {
      let partner_filter_data = data.filter(
        (singleData) => singleData.partner._id === filterStatusObj.partner_id
      );
      let client_filter_data = partner_filter_data.filter(
        (singleData) => singleData.client._id === filterStatusObj.client_id
      );
      return client_filter_data.filter(
        (singleData) => singleData.client._id === client_id
      );
    }
  }
  function onClickClearFilter() {
    if (
      filterStatusObj.recruiter_id === "123" &&
      filterStatusObj.client_id === "123" &&
      filterStatusObj.partner_id === "123"
    ) {
      return;
    }
    var sendData = {
      recruiter_id: "123",
      client_id: "123",
      partner_id: "123",
    };
    dispatch(changeFilterData(sendData));
    // dispatch(onChangeFilterStatusObject(sendData));
  }
  function getDate(data) {
    if (
      (props.selectedStatusName === "Active Placement" ||
        props.selectedStatusName === "PLACEMENT") &&
      data?.offer_details[0] !== undefined
    ) {
      return data?.offer_details[0].start_date !== ""
        ? moment(data?.offer_details[0].start_date).format(DATE_FORMATE)
        : CommonString.na;
    }
    if (
      (props.selectedStatusName === "Offers" ||
        props.selectedStatusName === "OFFER") &&
      data?.offer_details[0] !== undefined
    ) {
      return data?.offer_details[0].offer_date !== ""
        ? moment(data?.offer_details[0].offer_date).format(DATE_FORMATE)
        : CommonString.na;
    }
    return moment(data?.interviewData?.data?.date).format(DATE_FORMATE);
  }
  function getTitle() {
    if (
      props.selectedStatusName === "Active Placement" ||
      props.selectedStatusName === "PLACEMENT"
    ) {
      return "Placement Date";
    }
    if (
      props.selectedStatusName === "Offers" ||
      props.selectedStatusName === "OFFER"
    ) {
      return "Offer Date";
    }
    return "Interview Date";
  }
  var is_visible_date =
    props.selectedStatusName === "Active Placement" ||
    props.selectedStatusName === "PLACEMENT" ||
    props.selectedStatusName === "Offers" ||
    props.selectedStatusName === "OFFER" ||
    props.selectedStatusName === "INTERVIEW" ||
    props.selectedStatusName === "Interviews";

  return (
    <div>
      <Modal style={{ zIndex: 1 }} open={props.open} onClose={props.onClose}>
        <Box sx={style}>
          <div className="br-5">
            <TitleContainer>
              <ModalTitle style={{ margin: 0 }}>
                {props.selectedStatusName}
              </ModalTitle>
              <FlexContainer>
                <ClearFilterText
                  style={{ marginRight: 10 }}
                  onClick={() => onClickClearFilter()}
                >
                  {filterStatusObj.recruiter_id === "123" &&
                  filterStatusObj.client_id === "123" &&
                  filterStatusObj.partner_id === "123"
                    ? ""
                    : CommonString.clear_filter}
                </ClearFilterText>
                <CustomTooltip
                  placement={CommonString.top_placement}
                  title={CommonTitle.close}
                  name={
                    <Icon
                      width={24}
                      height={24}
                      icon={CommonIcon.modal_close}
                      onClick={() => props.onClose()}
                    />
                  }
                />
              </FlexContainer>
            </TitleContainer>
            <TableContainerForCountData>
              <Table style={{ border: "1px solid red !important" }}>
                <TableRow>
                  <TableTh width={"2%"}>No.</TableTh>
                  <TableTh>Applicant</TableTh>
                  <TableTh>Position title</TableTh>
                  <TableTh>Job ID</TableTh>
                  <TableTh>
                    <SubmissionFilter
                      selectedValue={filterStatusObj.partner_id}
                      is_active={filterStatusObj.partner_id !== "123"}
                      list={temp_partner_data}
                      name={"Partner"}
                    />
                  </TableTh>
                  <TableTh>
                    <SubmissionFilter
                      selectedValue={filterStatusObj.client_id}
                      is_active={filterStatusObj.client_id !== "123"}
                      list={temp_client_data}
                      name={"Client"}
                    />
                  </TableTh>
                  <TableTh>
                    {onCheckLevelAccessibility() ? (
                      <SubmissionFilter
                        selectedValue={filterStatusObj.recruiter_id}
                        is_active={filterStatusObj.recruiter_id !== "123"}
                        list={temp_recruiter_list}
                        name={"Recruiter Name"}
                      />
                    ) : (
                      "Recruiter"
                    )}
                  </TableTh>
                  {is_visible_date ? (
                    <TableTh>
                      <OrderFilter
                        selectedOrder={selectedOrder}
                        getValue={(e) => setSelectedOrder(e)}
                        name={getTitle()}
                      />
                    </TableTh>
                  ) : (
                    <></>
                  )}
                  {props.selectedStatusName === "Interviews" ? (
                    <TableTh>Interview Time</TableTh>
                  ) : (
                    <></>
                  )}
                  {/* <TableTh width={"5%"}></TableTh> */}
                </TableRow>
                {props.isLoadingStatusData ? (
                  <></>
                ) : (
                  onFilterData()?.map((singleData, index) => {
                    const { client, parentJob, partner, recruiter } =
                      singleData;
                    return (
                      <TableRow
                        onDoubleClick={() =>
                          props.onViewSubmission(VIEW_SUBMISSION, singleData)
                        }
                        key={index}
                      >
                        <TableTd
                          ListLength={SUBMISSION_TH.length}
                          style={{ paddingLeft: 10 }}
                        >
                          {index + 1}
                        </TableTd>
                        <TableTd ListLength={SUBMISSION_TH.length}>
                          <CustomizedTooltip
                            name={formatName(singleData?.full_name)}
                            title={formatName(singleData?.full_name)}
                          />
                        </TableTd>
                        <TableTd
                          style={{ maxWidth: 170 }}
                          ListLength={SUBMISSION_TH.length}
                        >
                          <CustomizedTooltip
                            name={parentJob?.position_title}
                            title={parentJob?.position_title}
                          />
                        </TableTd>
                        <TableTd ListLength={SUBMISSION_TH.length}>
                          {parentJob?.client_position_id === ""
                            ? "NA"
                            : parentJob?.client_position_id}
                        </TableTd>
                        <TableTd ListLength={SUBMISSION_TH.length}>
                          {getClientName(partner?.name)}
                        </TableTd>
                        <TableTd ListLength={SUBMISSION_TH.length}>
                          {getClientName(client?.name)}
                        </TableTd>
                        <TableTd
                          textAlignLeft={true}
                          ListLength={SUBMISSION_TH.length}
                        >
                          {recruiter?.name?.split(" ")[0]}
                        </TableTd>
                        {is_visible_date ? (
                          <TableTd
                            style={{ width: 90 }}
                            ListLength={SUBMISSION_TH.length}
                          >
                            {getDate(singleData)}
                          </TableTd>
                        ) : (
                          <></>
                        )}
                        {props.selectedStatusName === "Interviews" ? (
                          <TableTd
                            onClick={(e) => e.stopPropagation()}
                            ListLength={SUBMISSION_TH.length}
                          >
                            {singleData?.interviewData?.data?.interview_time !==
                            null
                              ? `${convertTo12HourFormat(
                                  singleData?.interviewData?.data
                                    ?.interview_time
                                )} ${
                                  singleData?.interviewData?.data?.time_zone
                                }`
                              : singleData?.interviewData?.data?.time}
                          </TableTd>
                        ) : (
                          <></>
                        )}
                      </TableRow>
                    );
                  })
                )}
              </Table>
              {!props.isLoadingStatusData && onFilterData()?.length === 0 ? (
                <TableNoDataContainer>
                  <img src={NoData} alt="" />
                </TableNoDataContainer>
              ) : (
                <></>
              )}
            </TableContainerForCountData>
            {props.isLoadingStatusData ? <LoaderForTable /> : <></>}
          </div>
        </Box>
      </Modal>
    </div>
  );
}
