import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import CustomTooltip from "../CommonComponet/Tooltip";
import TextWithIcon from "../CommonComponet/TextWithIcon";
import { useDispatch, useSelector } from "react-redux";
import {
  AddCommentText,
  ButtonWithoutBackground,
  FlexContainer,
  GroupBtnContainer,
  HR,
  OutlinedBtn,
  ReadMoreReadLessContent,
  ReadMoreReadLessText,
  SolidBtn,
} from "../../style/common.style";
import { ButtonName, CommonString } from "../../Utils/CommonString";
import { CommonIcon } from "../../Utils/CommonIcon";
import {
  ApplicantAddedBy,
  ApplicantAddedByContainer,
  IsVisibleComment,
  IsVisibleContactDetail,
  IsVisibleSkill,
  RowTileContainer,
  RowTileTitle,
  TextWithCopyIcon,
} from "../../style/applicant.style";
import {
  changeButtonName,
  changeIsOpenModal,
  changeSelectedFileName,
  setSelectedApplicant,
  updateApplicantData,
} from "../../feature/store/ApplicantStore";
import { CommonColor } from "../../Utils/color";
import { formatPhoneNumber } from "../../Utils/utilitiFunction";
import { formatName } from "../../Utils/validation";
import CustomButton from "../CommonComponet/Button";
import {
  UPDATE_APPLICANT_ERROR,
  UPDATE_APPLICANT_SUCCESS,
} from "../../Utils/alertMessage";
import ApplicantContoller from "../../controller/ApplicantContoller";
import { useAlert } from "react-alert";

export default function ApplicantCard(props) {
  const alert = useAlert();
  const [display, setDisplay] = useState(false);
  const [comment, setComment] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [onClickReadmore, setonClickReadmore] = useState(true);
  const [onClickReadmoreComment, setonClickReadmoreComment] = useState(true);
  var dispatch = useDispatch();
  const applicatContoller = new ApplicantContoller();
  const access_data_by_user_id = useSelector(
    (state) => state.counter.access_data_by_user_id
  );
  const selectedApplicant = useSelector(
    (state) => state.ApplicantStore.selectedApplicant
  );
  useEffect(() => {
    setComment(props.data.comment);
  }, []);

  function getFullName(data) {
    const first_name = formatName(data.first_name);
    const middle_name = formatName(data.middle_name);
    const last_name = formatName(data.last_name);
    return `${first_name} ${middle_name} ${last_name}`;
  }
  function getCityName(data) {
    if (
      data.state === " " &&
      data.zip === "" &&
      (data.city === " " || data.city === "")
    ) {
      return "";
    }
    if (data.state === " " && data.zip !== "" && data.city === " ") {
      return data.zip;
    }
    if (data.state !== " " && data.zip !== "" && data.city === " ") {
      return `${data.state}, ${data.zip}`;
    }
    if (data.state !== " " && data.zip === "" && data.city !== " ") {
      return `${data.city}, ${data.state}`;
    }
    if (data.state === " " && data.zip === "" && data.city !== " ") {
      return `${data.city}`;
    }
    return `${data.city}, ${data.state.trim()}, ${data.zip}`;
  }
  const handleChildClick = function (e) {
    e.stopPropagation();
  };
  function afterCopyDisplayText() {
    setDisplay(true);
    setTimeout(() => {
      setDisplay(false);
    }, 2000);
  }
  function onClickAPPlicant() {
    dispatch(setSelectedApplicant(props.data));
  }
  async function onAddComment() {
    var sendData = {
      ...props.data,
      comment: comment,
    };
    var res = await applicatContoller.updateApplicant(sendData);
    if (res.data.status === 200) {
      dispatch(updateApplicantData(sendData));
      // dispatch(changeIsOpenModal(false));
      // dispatch(changeIsLoadingInModal(false));
      alert.show(UPDATE_APPLICANT_SUCCESS, { type: CommonString.success });
    } else {
      // dispatch(changeIsLoadingInModal(false));
      alert.show(UPDATE_APPLICANT_ERROR, { type: CommonString.error });
    }
  }
  function getTextWithIconAndCopy(data, icon) {
    return (
      <IsVisibleContactDetail isVisible={data}>
        <TextWithIcon
          icon={icon}
          text={
            <CustomTooltip
              placement={CommonString.top_placement}
              title={display ? CommonString.copied : CommonString.copy}
              name={
                <TextWithCopyIcon
                  email={true}
                  onMouseLeave={() => setDisplay(false)}
                  onClick={(e) => {
                    navigator.clipboard.writeText(data);
                    afterCopyDisplayText();
                    handleChildClick(e);
                  }}
                >
                  {data}
                  <Icon icon={CommonIcon.copy} />
                </TextWithCopyIcon>
              }
            />
          }
        />
      </IsVisibleContactDetail>
    );
  }
  return (
    <RowTileContainer
      isActive={selectedApplicant._id === props.data._id}
      onClick={() => onClickAPPlicant()}
    >
      <ApplicantAddedByContainer>
        <RowTileTitle>{getFullName(props.data)}</RowTileTitle>
        <ApplicantAddedBy>{props.data.added_by}</ApplicantAddedBy>
      </ApplicantAddedByContainer>
      <TextWithIcon
        style={{ color: CommonColor.primary_color }}
        color={CommonColor.primary_color}
        icon={CommonIcon.bag}
        text={props.data.designation}
      />
      <div>
        {getTextWithIconAndCopy(props.data.primary_email, CommonIcon.email)}
        {getTextWithIconAndCopy(
          formatPhoneNumber(props.data.phone),
          CommonIcon.call
        )}
      </div>
      <TextWithIcon icon={CommonIcon.map} text={getCityName(props.data)} />
      <HR data={props.data.skills.length === 0 || props.data.skills === "--"} />
      <IsVisibleSkill
        data={props.data}
        style={{
          display:
            props.data.skills.length === 0 || props.data.skills === "--"
              ? "none"
              : "block",
        }}
      >
        {props.data.skills.trim().length >= 100 ? (
          <>
            <ReadMoreReadLessContent onClickReadmore={onClickReadmore}>
              {props.data.skills}
            </ReadMoreReadLessContent>
            <ReadMoreReadLessText
              onClick={(e) => {
                e.stopPropagation();
                setonClickReadmore(!onClickReadmore);
              }}
            >
              {onClickReadmore
                ? CommonString.read_more
                : CommonString.read_less}
            </ReadMoreReadLessText>
          </>
        ) : (
          props.data.skills
        )}
      </IsVisibleSkill>
      <HR data={props.data.comment === ""} />
      <IsVisibleComment
        style={{
          display:
            props.data.comment.length === 0 || props.data.comment === "--"
              ? "none"
              : "block",
        }}
        data={props.data}
      >
        {props.data.comment.trim().length >= 100 ? (
          <>
            <ApplicantAddedBy>comment</ApplicantAddedBy>
            <br />
            <ReadMoreReadLessContent onClickReadmore={onClickReadmoreComment}>
              {props.data.comment}
            </ReadMoreReadLessContent>
            <ReadMoreReadLessText
              onClick={(e) => {
                e.stopPropagation();
                setonClickReadmoreComment(!onClickReadmoreComment);
              }}
            >
              {onClickReadmoreComment
                ? CommonString.read_more
                : CommonString.read_less}
            </ReadMoreReadLessText>
          </>
        ) : (
          props.data.comment
        )}
      </IsVisibleComment>
      <AddCommentText onClick={() => setIsVisible(true)}>
        {props.data.comment.length === 0 || props.data.comment === "--"
          ? CommonString.add_comment
          : CommonString.update_comment}
      </AddCommentText>
      {isVisible ? (
        <FlexContainer className="commentContainer">
          <input
            value={comment || ""}
            onChange={(e) => setComment(e.target.value)}
            placeholder={
              props.data.comment.length === 0 || props.data.comment === "--"
                ? CommonString.add_comment
                : CommonString.update_comment
            }
          />
          <CustomButton
            noMarginTop={true}
            onClick={onAddComment}
            name={
              props.data.comment.length === 0 || props.data.comment === "--"
                ? CommonString.add
                : CommonString.update
            }
          />
        </FlexContainer>
      ) : (
        <></>
      )}
      <GroupBtnContainer>
        <FlexContainer>
          {access_data_by_user_id.includes("applicant_update") ? (
            <OutlinedBtn
              onClick={() => {
                onClickAPPlicant(ButtonName.edit_applicant);
                dispatch(changeIsOpenModal(true));
                dispatch(changeButtonName(ButtonName.edit_applicant));
                dispatch(changeSelectedFileName(null));
              }}
            >
              <TextWithIcon icon={CommonIcon.edit} text="Edit" />
            </OutlinedBtn>
          ) : (
            <></>
          )}
          {access_data_by_user_id.includes("close_applicant") ? (
            <SolidBtn
              onClick={() => {
                onClickAPPlicant(ButtonName.close_applicant);
                dispatch(changeIsOpenModal(true));
                dispatch(changeButtonName(ButtonName.close_applicant));
              }}
            >
              <TextWithIcon icon={CommonIcon.lock_line} text="Close" />
            </SolidBtn>
          ) : (
            <></>
          )}
        </FlexContainer>
        <ButtonWithoutBackground
          endIcon={<Icon size="small" icon="fe:arrow-right" />}
          onClick={() => {
            onClickAPPlicant();
            dispatch(changeIsOpenModal(true));
            dispatch(changeButtonName(ButtonName.view_applicant));
          }}
          variant="text"
        >
          <div>View</div>
        </ButtonWithoutBackground>
      </GroupBtnContainer>
    </RowTileContainer>
  );
}
