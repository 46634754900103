import React, { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import FormInput from "../FormInput";
import {
  changePartnerId,
  deletePartnerId,
} from "../../../feature/store/JobReduxStore";
import { FormControl, MenuItem, OutlinedInput, Select } from "@mui/material";
import { CheckBoxContainer } from "../../../style/common.style";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 4;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export default function PatnerSelectionComponent(props) {
  const inputClick = useRef();
  const dispatch = useDispatch();
  const [selectedPartnerList, setSelectedPartnerList] = React.useState([]);
  const temp_partner_data = useSelector(
    (state) => state.ClientReduxStore.temp_partner_data
  );
  const handleChange = (data) => {
    var checked = selectedPartnerList.includes(data?.name);
    if (!checked) {
      selectedPartnerList.push(data?.name);
      dispatch(changePartnerId(data));
      return;
    }
    let newData = selectedPartnerList.filter(function (singleData) {
      return singleData !== data?.name;
    });
    dispatch(deletePartnerId(data.id));
    setSelectedPartnerList(newData);
  };

  return temp_partner_data.length === 0 ? (
    <FormInput value="" placeholder="Partner Name" disabled={true} />
  ) : (
    <>
      <div className="selectMultiple">
        <label>Select Partner</label>
        <FormControl style={{ width: "100%" }}>
          <Select
            size="small"
            style={{ border: "1px solid lightgrey", padding: 0 }}
            multiple
            displayEmpty
            value={selectedPartnerList}
            input={<OutlinedInput />}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return (
                  <p style={{ marginLeft: 0, marginTop: -3 }}>Select Partner</p>
                );
              }
              return (
                <p
                  style={{
                    marginLeft: 0,
                    width: "100%",
                    overflowX: "auto",
                    marginTop: -5,
                  }}
                >
                  {selected.join(", ")}
                </p>
              );
            }}
            MenuProps={MenuProps}
            inputProps={{ "aria-label": "Without label" }}
          >
            {temp_partner_data.map((data) => (
              <MenuItem style={{ width: "100%" }} key={data} value={data?.name}>
                <CheckBoxContainer onClick={() => handleChange(data)}>
                  <input
                    ref={inputClick}
                    id="default-checkbox"
                    type="checkbox"
                    name={data?.name}
                    // onChange={(event) => handleChange(event, data)}
                    checked={selectedPartnerList.includes(data?.name)}
                    value=""
                  />
                  <label>{data?.name}</label>
                </CheckBoxContainer>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {/* <div
        onClick={handleClick}
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {partner_id.map((item, index) => (
          <CustomTab
            key={index}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(deletePartnerId(item.id));
            }}
          >
            <CustomChip label={item.name} />
            <IconTab icon={CommonIcon.ep_close} />
          </CustomTab>
        ))}
        <input
          style={{ border: "none", minHeight: 40 }}
          placeholder="Partner Name"
        />
      </div>
      <div>
        <Menu
          id="fade-menu"
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          {temp_partner_data.map((item, index) => {
            var i = partner_id.findIndex((sdf) => sdf.id === item.id);
            return (
              <CustomMenuContainer
                key={index}
                onClick={() => onAddPartner(item, index)}
              >
                <p>{item.name}</p>
                {i > -1 ? (
                  <div
                    style={{
                      height: 10,
                      width: 10,
                      color: CommonColor.primary_color,
                    }}
                  >
                    <CheckIcon style={{ marginLeft: "-38px" }} />
                  </div>
                ) : (
                  <div />
                )}
              </CustomMenuContainer>
            );
          })}
        </Menu>
      </div> */}
    </>
  );
}
