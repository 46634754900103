export const ADD_JOB_SUCCESS = "Job successfully added.";
export const ADD_JOB_ERROR = "Error While add job, please try again.";
export const UPDATE_JOB_SUCCESS = "Job successfully updated.";
export const UPDATE_JOB_ERROR = "Error While updating job, please try again.";
export const JOB_DESCRIPTION_ERROR = "Gotcha... Please fill job description.";
export const DELETE_JOB_SUCCESS = "Job successfully deleted.";
export const DELETE_JOB_ERROR = "Error While deleting job, please try again.";
export const UPDATE_JOB_STATUS_SUCCESS = "Job status successfully updated.";
export const UPDATE_JOB_STATUS_IS_SAME = "This possition is already ";
export const UPDATE_JOB_STATUS_ERROR =
  "Error While updating job status, please try again.";
export const ERROR_MESSAGE_JOB =
  "Please select partner and fill all information and try again.";
export const ERROR_MESSAGE_ATTACHMENT =
  "Please select attachment and try again.";
export const ADD_ATTACHMENT_SUCCESS = "Attachment successfully added.";
export const DELETE_FILE_SUCCESS = "File successfully deleted.";
export const DELETE_FILE_ERROR = "Error While deleting file, please try again.";

export const ADD_APPLICANT_SUCCESS = "Applicant successfully added.";
export const RESUME_ERROR = "Please attach resume before adding applicant.";
export const ADD_APPLICANT_ERROR =
  "Error While add applicant, please try again.";
export const UPDATE_APPLICANT_SUCCESS = "Applicant successfully updated.";
export const UPDATE_APPLICANT_ERROR =
  "Error While updating applicant, please try again.";
export const DELETE_APPLICANT_SUCCESS = "Applicant successfully deleted.";
export const DELETE_APPLICANT_ERROR =
  "Error While deleting applicant, please try again.";

export const ADD_LEVEL_SUCCESS = "Level successfully added.";
export const ADD_LEVEL_ERROR = "Error While add level, please try again.";
export const UPDATE_LEVEL_SUCCESS = "Level successfully updated.";
export const UPDATE_LEVEL_ERROR =
  "Error While updating level, please try again.";
export const DELETE_LEVEL_SUCCESS = "Level successfully deleted.";
export const DELETE_LEVEL_ERROR =
  "Error While deleting level, please try again.";

export const ADD_CLIENT_SUCCESS = "Client successfully added.";
export const ADD_CLIENT_ERROR = "Error While add client, please try again.";
export const UPDATE_CLIENT_SUCCESS = "Client successfully updated.";
export const UPDATE_CLIENT_ERROR =
  "Error While updating client, please try again.";
export const DELETE_CLIENT_SUCCESS = "Client successfully deleted.";
export const DELETE_CLIENT_ERROR =
  "Error While deleting client, please try again.";

export const ADD_PARTNER_SUCCESS = "Partner successfully added.";
export const ADD_PARTNER_ERROR = "Error While add partner, please try again.";
export const UPDATE_PARTNER_SUCCESS = "Partner successfully updated.";
export const UPDATE_PARTNER_ERROR =
  "Error While updating partner, please try again.";
export const DELETE_PARTNER_SUCCESS = "Partner successfully deleted.";
export const DELETE_PARTNER_ERROR =
  "Error While deleting partner, please try again.";

export const ADD_RECRUITER_SUCCESS = "Recruiter successfully added.";
export const ADD_RECRUITER_ERROR =
  "Error While add recruiter, please try again.";
export const UPDATE_RECRUITER_SUCCESS = "Recruiter successfully updated.";
export const UPDATE_RECRUITER_ERROR =
  "Error While updating recruiter, please try again.";
export const DELETE_RECRUITER_SUCCESS = "Recruiter successfully deleted.";
export const DELETE_RECRUITER_ERROR =
  "Error While deleting recruiter, please try again.";

export const ADD_SKILL_SUCCESS = "Skill successfully added.";
export const ADD_SKILL_ERROR = "Error While add skill, please try again.";
export const UPDATE_SKILL_SUCCESS = "Skill successfully updated.";
export const UPDATE_SKILL_ERROR =
  "Error While updating skill, please try again.";
export const DELETE_SKILL_SUCCESS = "Skill successfully deleted.";
export const DELETE_SKILL_ERROR =
  "Error While deleting skill, please try again.";

export const UPDATE_SUBMISSION_SUCCESS = "Submission successfully added.";
export const UPDATE_SUBMISSION_ERROR =
  "Error While adding submission, please try again.";
export const UPDATE_SUBMISSION_STATUS_SUCCESS = " status successfully updated.";
export const UPDATE_INTERVIEW_DETAIL = "Update interview successfully updated.";
export const ERROR_UPDATE_INTERVIEW_DETAIL =
  "Error While updating interview details, please try again.";
export const UPDATE_SUBMISSION_TRACKER =
  "Submission tracker successfully updated.";
export const UPDATE_SUBMISSION_TRACKER_ERROR =
  "Error While updating submission tracker, please try again.";
export const UPDATE_SUBMISSION_STATUS_ERROR =
  "Error While updating submission status, please try again.";
export const DELETE_SUBMISSION_SUCCESS = "Submission successfully deleted.";
export const DELETE_SUBMISSION_ERROR =
  "Error While deleting submission, please try again.";

export const JOB_CHECK_EXIST_ERROR =
  "This client position id is already exist.";
export const FILL_ALL_DATA_IN_INTERVIEW_DETAILS =
  "Please fill all data in interview details.";
export const FILL_ALL_DATA_IN_PROJECT_DETAILS =
  "Please fill all data in project details.";
export const DATA_IS_LOADING = "Please wait somthing is wrong data is loading.";
export const SELECTE_JOB = "Please select job.";
export const STATUS_ALREADY_UPDATED = "Status is already updated.";

export const APPLICANT_CHECK_EXIST_ERROR = "This applicant is already exist.";
export const FILE_ERROR =
  "File does not contain . in name please rome and then upload.";
export const REPORTCALENDARALERTMESSAGE =
  "please select duration from calendar";
  export const Select_all_filter =
  "please select all filter option";
export const CALL_TRACKER_UPDATE_ERROR =
  "The update cannot be made on a non-scheduled date.";
