import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../../assets/images/logo.png";
import { useHistory } from "react-router-dom";
import Menu from "../../component/Menu/Menu";
import {
  getNotificationData,
  getRecruiterData,
  getApplicantStatus,
  changeMenuStatus,
  getDashBoardData,
  changeUserDetail,
  getVisibility,
  onChangeAccessDataById,
  onChangeUserData,
} from "../../feature/store/ReduxStore";
import { useDispatch, useSelector } from "react-redux";
import RecruiterController from "../../controller/RecruiterController";
import NotificationController from "../../controller/NotificationController";
import { Drawer } from "../../style/home.style";
import { motion } from "framer-motion/dist/framer-motion";
import GetView from "./HomeView";
import {
  DrawerImg,
  DrawerImgContainer,
  DrawerLeftIcon,
  DrawerMain,
  DrawerMainContainer,
  DrawerText,
} from "../../style/menu.style";
import {
  CommonString,
  CommonStringForLocalStorage,
} from "../../Utils/CommonString";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { changeTempRecruiterList } from "../../feature/store/JobReduxStore";
import { makeStyles } from "@material-ui/core";
import { CommonColor } from "../../Utils/color";
import JobController from "../../controller/JobController";
import { DrawerHeader } from "../../style/dashboard.style";
import { DASHBOARD } from "../../Utils/enum";
import {
  getAccessVisibility,
  onCheckLevelAccessibility,
  onCheckLevelAccessibilityForDashboard,
} from "../../Utils/utilitiFunction";
import moment from "moment";
import ReportController from "../../controller/ReportController";
import { SubmissionController } from "../../controller/SubmissionController";
import {
  changeChatList,
  changeTempClientData,
  changeTempPartnerData,
  removeChatList,
} from "../../feature/store/SubmissionReduxStore";
import PartnerController from "../../controller/PartnerController";
import ClientController from "../../controller/ClientController";

const useStyles = makeStyles({
  paper: {
    color: CommonColor.white,
    zIndex: "1",
    height: "100vh",
    background: CommonColor.primary_color + "!important",
  },
});

export default function MiniDrawer() {
  const reportController = new ReportController();
  const partnerController = new PartnerController();
  const clientController = new ClientController();
  let history = useHistory();
  const classes = useStyles();
  const recruiterController = new RecruiterController();
  const notificationController = new NotificationController();
  const jobController = new JobController();
  const submissionController = new SubmissionController();
  const dispatch = useDispatch();
  const menuStatus = useSelector((state) => state.counter.menuStatus);
  const [open, setOpen] = React.useState(false);
  const [isLoading, setisLoading] = useState(false);
  useEffect(() => {
    getData();
    getPartnerData();
    getClientData();
    getRecruiter();
  }, []);

  useEffect(() => {
    if (menuStatus === DASHBOARD) {
      setOpen(false);
    }
  }, [menuStatus]);

  async function getData() {
    var user = localStorage.getItem("USER");
    dispatch(onChangeUserData(JSON.parse(user).user));
    var access_res = await recruiterController.getAccessById({
      user_id: JSON.parse(user).user._id,
    });
    let access = getAccessVisibility(access_res);
    dispatch(onChangeAccessDataById(access));
    var isVisible = onCheckLevelAccessibility();
    dispatch(getVisibility(isVisible));
    dispatch(removeChatList([]));
    var tk = localStorage.getItem(CommonStringForLocalStorage.token);
    if (tk === null) {
      history.push("/");
    }
    setisLoading(true);
    var submissionData = await submissionController.getSubmissionWithStatus(1);
    dispatch(changeChatList(submissionData));
    if (onCheckLevelAccessibilityForDashboard()) {
      var sendData = {
        recruiter_id: JSON.parse(user).user._id,
        year: moment().year(),
      };
      dispatch(changeUserDetail(JSON.parse(user).user));
      var res_2 = await reportController.getYearSubmissionReport(sendData);
      dispatch(getDashBoardData(res_2.data));
      return;
    }
    var dataCount = await jobController.getAllCount();
    dispatch(getDashBoardData(dataCount));
    setisLoading(false);
    var data = await recruiterController.getRecruiters();
    dispatch(getRecruiterData(data));
    var tempRecruiterList = [];
    tempRecruiterList.push(
      { name: "All", _id: "123", is_recruiter: 1 },
      ...data
    );
    dispatch(changeTempRecruiterList(tempRecruiterList));
    var notification_data = await notificationController.getNotification();
    dispatch(getNotificationData(notification_data));
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  async function getPartnerData() {
    var data = await partnerController.getPartners();
    const filteredData = data.filter(
      (singleData) => singleData.is_active === 1
    );
    dispatch(
      changeTempPartnerData([{ name: "All", _id: "123" }, ...filteredData])
    );
  }
  async function getClientData() {
    var data = await clientController.getClients();
    const filteredData = data.filter(
      (singleData) => singleData._is_active === 0
    );
    dispatch(
      changeTempClientData([{ name: "All", _id: "123" }, ...filteredData])
    );
  }
  async function getRecruiter() {
    const temprecruiterlist = [];
    var data = await recruiterController.getRecruiters();
    temprecruiterlist.push(
      { name: "All", _id: "123", is_recruiter: 1 },
      ...data
    );
    dispatch(changeTempRecruiterList(temprecruiterlist));
  }
  return (
    <DrawerMainContainer>
      <Drawer
        classes={{ paper: classes.paper }}
        style={{ zIndex: 0 }}
        variant={CommonString.permanent}
        open={open}
      >
        <DrawerHeader>
          <DrawerText open={open} onClick={handleDrawerOpen}>
            <MenuIcon />
          </DrawerText>
          <DrawerImgContainer open={open}>
            <DrawerImg src={logo} alt={CommonString.logo} />
          </DrawerImgContainer>
          <DrawerLeftIcon onClick={handleDrawerClose} open={open}>
            <ChevronLeftIcon />
          </DrawerLeftIcon>
        </DrawerHeader>
        <List>
          <Menu
            open={open}
            onClick={(e) => {
              dispatch(changeMenuStatus(e));
              dispatch(getApplicantStatus(true));
            }}
          />
        </List>
      </Drawer>
      <DrawerMain>
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
        >
          {GetView(
            menuStatus,
            (e) => {
              dispatch(changeMenuStatus(e));
            },
            isLoading
          )}
        </motion.div>
      </DrawerMain>
    </DrawerMainContainer>
  );
}
