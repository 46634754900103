import JobProvider from "../services/provider/JobProvider";
import { useAlert } from "react-alert";
import {
  DELETE_FILE_ERROR,
  DELETE_FILE_SUCCESS,
  ERROR_MESSAGE_ATTACHMENT,
} from "../Utils/alertMessage";
import { CommonString } from "../Utils/CommonString";
import { useDispatch, useSelector } from "react-redux";
import {
  changeBillRate,
  changeClientList,
  changeJobDescription,
  changeSelectedClientValue,
  changeSkillMatrix,
  deleteSelectedFileName,
} from "../feature/store/JobReduxStore";
import PartnerProvider from "../services/provider/PartnerProvider";
import {
  changeITempPartnerData,
  onchangeBillrate,
  onchangeSelectedPartnerList,
  onchangeSelectedPartnerTaxList,
} from "../feature/store/ClientReduxStore";
import ClientController from "./ClientController";
import {
  addJobData,
  getjobData,
  onChangeTotalJobCount,
} from "../feature/store/ReduxStore";
import { changeIsOpenModal } from "../feature/store/ApplicantStore";

export default class JobController {
  constructor() {
    this.jobProvider = new JobProvider();
    this.partnerProvider = new PartnerProvider();
    this.clientController = new ClientController();
    this.alert = new useAlert();
    this.dispatch = new useDispatch();
    this.client_due_date = new useSelector(
      (state) => state.JobReduxStore.client_due_date
    );
    this.selected_partner_tax_list = new useSelector(
      (state) => state.ClientReduxStore.selected_partner_tax_list
    );
    this.skill_matrix = new useSelector(
      (state) => state.JobReduxStore.skill_matrix
    );
    this.job_description = new useSelector(
      (state) => state.JobReduxStore.job_description
    );
    this.selected_value_client = new useSelector(
      (state) => state.JobReduxStore.selected_value_client
    );
    this.bill_rate = new useSelector((state) => state.JobReduxStore.bill_rate);
    this.temp_selected_file = new useSelector(
      (state) => state.JobReduxStore.temp_selected_file
    );
    this.selected_data = new useSelector(
      (state) => state.JobReduxStore.selected_data
    );
  }
  getAllCount = async () => {
    var res = await this.jobProvider.getAllCountList();
    return res.data;
  };
  getJobs = async (page) => {
    var res = await this.jobProvider.getJobList(page);
    return res.data.data;
  };
  getOpenJobs = async (page) => {
    var res = await this.jobProvider.getOpenJobList(page);
    return res.data.data;
  };
  getJobsByStatus = async (data, page) => {
    var res = await this.jobProvider.getJobListByStatus(data, page);
    this.dispatch(onChangeTotalJobCount(res.data.count));
    this.dispatch(getjobData(res.data.data));
    return res.data.data;
  };
  checkJobExist = async (data) => {
    var res = await this.jobProvider.checkJobExist(data);
    return res.data;
  };
  searchJob = async (data, page) => {
    var res = await this.jobProvider.searchJob(data, page);
    return res.data;
  };
  deleteJob = async (data) => {
    var res = await this.jobProvider.deleteJob(data);
    return res;
  };
  addJob = async (data) => {
    var res = await this.jobProvider.addNewJob(data);
    return res;
  };

  uploadandSaveAttachment = async (files, job_id) => {
    try {
      for (let i = 0; i < files.length; i++) {
        var file = files[i];
        const formData = new FormData();
        formData.append("attachment", file);
        var upload_res = await this.jobProvider.uploadAttachFile(formData);
        var attachmentData = {
          job_id: job_id,
          attachment_description: upload_res.data.data.url,
          attachment_name: file.name,
        };
        var attach_res = await this.jobProvider.attachFile(attachmentData);
        if (attach_res.data.status === 200) {
        } else {
          this.alert.show(ERROR_MESSAGE_ATTACHMENT, {
            type: CommonString.error,
          });
          return;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  updateJob = async (data, e) => {
    var res = await this.jobProvider.updateJob(data);
    if (res.data.status === 200) {
      for (let i = 0; i < e.length; i++) {
        var element = e[i];
        const formData = new FormData();
        formData.append("attachment", element);
        var upload_res = await this.jobProvider.uploadAttachFile(formData);
        var attachmentData = {
          job_id: res.data.data.job._id,
          attachment_description: upload_res.data.data.url,
          attachment_name: element.name,
        };
        var attach_res = await this.jobProvider.attachFile(attachmentData);
        if (attach_res.data.status === 200) {
          // console.log("===");
        } else {
          this.alert.show(ERROR_MESSAGE_ATTACHMENT, {
            type: CommonString.error,
          });
          return;
        }
      }
      return res;
    }
  };
  changeJobStatus = async (data) => {
    var res = await this.jobProvider.updateJobStatus(data);
    return res;
  };
  addAttachment = async (data) => {
    var res = await this.jobProvider.attachFile(data);

    return res;
  };
  uploadAttachment = async (data) => {
    var res = await this.jobProvider.uploadAttachFile(data);
    return res;
  };
  getAttachmentByID = async (data) => {
    var res = await this.jobProvider.getAttachmentByID(data);
    return res;
  };
  deleteAttachment = async (data) => {
    var fileData = {
      _id: data,
    };
    var res = await this.jobProvider.deleteAttachmentByID(fileData);
    if (res.status === 200) {
      this.dispatch(deleteSelectedFileName(fileData));
      this.alert.show(DELETE_FILE_SUCCESS, {
        type: CommonString.success,
      });
    } else {
      this.alert.show(DELETE_FILE_ERROR, {
        type: CommonString.error,
      });
    }
    return res;
  };
  searchByClientID = async (data) => {
    var res = await this.jobProvider.searchByClientID(data);
    return res;
  };
  getAllJobByStatus = async (data) => {
    var res = await this.jobProvider.getAllJobByStatus(data);
    return res;
  };
  onGetPArtner = async (x) => {
    var sendData = {
      job_id: x.job_detail._id,
    };
    var job_res = await this.jobProvider.getPartnerByJob(sendData);
    if (x === null) {
      return;
    }
    var data = {
      client_id: x.job_detail.client._id,
    };
    var res = await this.partnerProvider.getPartnerByClient(data);
    var result = await res.data.data.filter(
      (value1) =>
        !job_res.data.data.some(
          (value2) => value2.partner_id === value1.patner._id
        )
    );
    var temp = [];
    var taxTempData = [];
    for (let index = 0; index < result.length; index++) {
      const element = result[index];
      var is_exist = true;
      if (is_exist) {
        var d = {
          name: element.patner.name,
          id: element.patner._id,
          _1099: element.margin_data._1099,
          w2_with_benifit: element.margin_data.w2_with_benifit,
          w2_without_benifit: element.margin_data.w2_without_benifit,
          w2_salarized: element.margin_data.w2_salarized,
          working_percentage: element.margin_data.working_percentage,
          is_active: false,
          margin: element.margin_data.margin,
          is_in_percentage: element.margin_data.is_in_percentage,
        };
        var userData;
        userData = {
          partner_id: element.patner._id,
          name: element.patner.name,
          recruter_id: "",
          recruiter_name: "",
          _1099: 0,
          w2_with_benifits: 0,
          w2_without_benifit: 0,
          w2_salarized: 0,
          margin: "",
        };
        taxTempData.push(userData);
        temp.push(d);
      }
    }
    this.dispatch(onchangeSelectedPartnerList([]));
    this.dispatch(onchangeSelectedPartnerTaxList([]));
    this.dispatch(changeITempPartnerData(temp));
    this.dispatch(onchangeBillrate(x.job_detail.bill_rate));
  };
  onAddNewPartner = async (e, recruiter_name) => {
    var data = {
      job_id: e.job_detail._id,
      open_date: null,
      due_date: null,
      position_title: e.job_detail.position_title ?? "",
      client_id: e.job_detail.client._id ?? "",
      department: e.job_detail.department ?? "",
      partner_id: JSON.stringify(this.selected_partner_tax_list) ?? "",
      status: e.job_detail.status,
      total_openings: e.job_detail.total_openings ?? 0,
      max_sub: e.job_detail.max_submission ?? 0,
      no_of_sub: 0,
      available_slots: 0,
      client_position_id: e.job_detail.client_position_id ?? "",
      pay_rate: 0,
      bill_rate: e.job_detail.bill_rate ?? 0,
      comment: e.job_detail.comment ?? "",
      tax_term: e.job_detail.tax_term ?? "",
      poc_name: e.job_detail.poc_name ?? "",
      zip_code: e.job_detail.zip_code ?? 0,
      position_description: e.job_detail.position_description
        ? e.job_detail.position_description.replace("'", "")
        : "",
      job_location: e.job_detail.job_location ?? "",
      prefred_skills: e.job_detail.prefred_skills ?? "",
      require_skill: e.job_detail.require_skill ?? "",
      require_education: e.job_detail.require_education ?? "",
      require_year_experience: e.job_detail.require_year_experience ?? 0,
      skill_matrix: e.job_detail.skill_matrix
        ? e.job_detail.skill_matrix.toString().replace("'", "")
        : "",
      required_sub_details:
        e.job !== undefined ? e.job_detail.required_sub_details : "",
      project_duration: e.job_detail.project_duration,
      recruiter_id: "1",
      recruiter_name: recruiter_name ?? "",
      c2c1099: 0,
      w2_with_benifits: 0,
      w2_without_benifits: 0,
      w2_salarized: 0,
      interview_mode: e.job_detail.interview_mode ?? "",
      job_type: e.job_detail.job_type ?? "",
      job_mode: e.job_detail.job_mode ?? "",
      working_hours: e.job_detail.working_hours ?? "",
      client_date: JSON.stringify(this.client_due_date),
    };
    var res = await this.jobProvider.onAddPartnerInJob(data);
    this.dispatch(changeIsOpenModal(false));
    this.dispatch(addJobData(res.data.data));
    this.alert.show("Add Partner job successfully.", {
      type: CommonString.success,
    });
  };
  onSubmitData = async (e, isUpdate, jobDescription, skillMatrix) => {
    var selectedPartnerList = this.selected_partner_tax_list;
    function getRecruiterName() {
      if (isUpdate) {
        return "";
      }
      var recruiterName = [];
      selectedPartnerList.map((data) =>
        recruiterName.push(data.recruiter_name)
      );
      return recruiterName;
    }
    var data = {
      open_date: "",
      due_date: "",
      position_title: e.position_title.value ?? "",
      client_id: this.selected_value_client.id ?? "",
      department: e.department.value ?? "",
      partner_id: JSON.stringify(this.selected_partner_tax_list) ?? "",
      status: "OPEN",
      total_openings: e.total_opening.value ?? 0,
      max_sub: e.max_submission.value ?? 0,
      no_of_sub: 0,
      available_slots: 0,
      client_position_id: e.client_position_id.value ?? "",
      pay_rate: 0,
      bill_rate: e.bill_rate.value ?? 0,
      comment: e.comment.value ?? "",
      tax_term: "",
      poc_name: "",
      zip_code: e.postal_code.value ?? 0,
      position_description:
        jobDescription !== "" ? jobDescription.replace("'", "") : "",
      job_location: e.job_location.value ?? "",
      prefred_skills: "",
      require_skill: "",
      require_education: e.required_education.value ?? "",
      require_year_experience: e.experience.value ?? 0,
      skill_matrix:
        skillMatrix !== "" ? skillMatrix.toString().replace("'", "") : "",
      required_sub_details: "",
      project_duration: e.job_duration.value + e.Job_duration_type.value,
      recruiter_id: "1",
      recruiter_name: getRecruiterName() ?? "",
      c2c1099: 0,
      w2_with_benifits: 0,
      w2_without_benifits: 0,
      w2_salarized: 0,
      interview_mode: e.interview_mode.value ?? "",
      job_type: e.job_type.value ?? "",
      job_mode: e.job_mode.value ?? "",
      working_hours: e.working_hour.value ?? "",
      client_date: JSON.stringify(this.client_due_date),
    };
    var res = await this.jobProvider.addNewJob(data);
    if (res.data.status === 200) {
      for (let i = 0; i < this.temp_selected_file.length; i++) {
        var element = this.temp_selected_file[i];
        const formData = new FormData();
        formData.append("attachment", element);
        var upload_res = await this.jobProvider.uploadAttachFile(formData);
        var attachmentData = {
          job_id: res.data.data[0].job._id,
          attachment_description: upload_res.data.data.url,
          attachment_name: element.name,
        };
        var attach_res = await this.jobProvider.attachFile(attachmentData);
        if (attach_res.data.status === 200) {
        } else {
          this.alert.show(ERROR_MESSAGE_ATTACHMENT, {
            type: CommonString.error,
          });
          return;
        }
      }
      return res;
    }
  };
  getClientData = async (data) => {
    var res = await this.clientController.getClients();
    var temp = [];
    for (let index = 0; index < res.length; index++) {
      const element = res[index];
      var d = {
        name: `${element.name}`,
        id: element._id,
        margin_percentage: element.margin_percentage,
      };
      if (data && data.job.client_id === element._id) {
        this.dispatch(changeSelectedClientValue(d));
      }
      temp.push(d);
    }
    this.dispatch(changeClientList(temp));
  };
  getPartnerData = async (e) => {
    if (e === undefined) {
      return;
    }
    var data = {
      client_id: e.job_detail.client_id,
    };
    var res = await this.partnerProvider.getPartnerByClient(data);
    var temp = [];
    var taxTempData = [];
    for (let index = 0; index < res.data.data.length; index++) {
      const element = res.data.data[index];
      var is_exist = true;
      // is_exist = e.partner._id === element.patner._id;
      if (is_exist) {
        this.dispatch(onchangeBillrate(this.bill_rate));
        this.dispatch(changeBillRate(this.bill_rate));
        var d = {
          name: element.patner.name,
          id: element.patner._id,
          _1099: element.margin_data._1099,
          w2_with_benifit: element.margin_data.w2_with_benifit,
          w2_without_benifit: element.margin_data.w2_without_benifit,
          w2_salarized: element.margin_data.w2_salarized,
          working_percentage: element.margin_data.working_percentage,
          is_active: false,
          margin: element.margin_data.margin,
          is_in_percentage: element.margin_data.is_in_percentage,
        };
        var userData;
        userData = {
          partner_id: element.patner._id,
          name: element.patner.name,
          recruter_id: "",
          recruiter_name: "",
          _1099: 0,
          w2_with_benifits: 0,
          w2_without_benifit: 0,
          w2_salarized: 0,
          margin: "",
        };

        taxTempData.push(userData);
        temp.push(d);
      }
    }
    this.dispatch(changeITempPartnerData(temp));
  };
  onGetPartnerInAddJob = async (x, isUpdate, e) => {
    if (x === null || x === undefined) {
      return;
    }
    var data = {
      client_id: isUpdate ? e.job_detail.client_id : x.id,
    };
    var res = await this.partnerProvider.getPartnerByClient(data);
    var temp = [];
    var taxTempData = [];
    for (let index = 0; index < res.data.data.length; index++) {
      const element = res.data.data[index];
      var is_exist = true;
      if (isUpdate) {
        is_exist = e.partner._id === element.patner._id;
      }
      if (is_exist) {
        this.dispatch(onchangeBillrate(this.bill_rate));
        this.dispatch(changeBillRate(this.bill_rate));
        var d = {
          name: element.patner.name,
          id: element.patner._id,
          _1099: element.margin_data._1099,
          w2_with_benifit: element.margin_data.w2_with_benifit,
          w2_without_benifit: element.margin_data.w2_without_benifit,
          w2_salarized: element.margin_data.w2_salarized,
          working_percentage: element.margin_data.working_percentage,
          is_active: false,
          margin: element.margin_data.margin,
          is_in_percentage: element.margin_data.is_in_percentage,
        };
        var userData;
        if (isUpdate) {
          // const { child_job } = e.child_job;
          // console.log("child_job",child_job,e.child_job)
          userData = {
            partner_id: element.patner._id,
            name: element.patner.name,
            recruter_id: e.recruiter_id,
            recruiter_name: e.recruiter_name,
            _1099: e._1099,
            w2_with_benifits: e.w2_with_benifits,
            w2_without_benifit: e.w2_without_benifit,
            w2_salarized: e.w2_salarized,
            margin: "",
          };
        } else {
          userData = {
            partner_id: element.patner._id,
            name: element.patner.name,
            recruter_id: "",
            recruiter_name: "",
            _1099: 0,
            w2_with_benifits: 0,
            w2_without_benifit: 0,
            w2_salarized: 0,
            margin: "",
          };
        }
        taxTempData.push(userData);
        temp.push(d);
      }
    }
    this.dispatch(onchangeSelectedPartnerList(temp));
    this.dispatch(onchangeSelectedPartnerTaxList(taxTempData));
    this.dispatch(changeITempPartnerData(temp));
    if (!isUpdate) {
      this.dispatch(onchangeBillrate(this.bill_rate));
    }
  };
  onCalculateBillRate = async (e) => {
    var BillRate = e.replace("$", "");
    this.dispatch(onchangeBillrate(BillRate));
    this.dispatch(changeBillRate(BillRate));
  };
  onPreFieldData = async (e) => {
    this.dispatch(
      changeJobDescription(this.selected_data.job.position_description)
    );
    this.dispatch(changeSkillMatrix(this.selected_data.job.skill_matrix));
    // this.dispatch(changeSelectedClientValue(e));
    e.position_title.value = this.selected_data.job.position_title;
    e.client_position_id.value = this.selected_data.job.client_position_id;
    e.total_opening.value = this.selected_data.child_job.total_opening;
    e.max_submission.value = this.selected_data.child_job.max_submission;
    e.bill_rate.value = this.selected_data.job.bill_rate;
    e.required_education.value = this.selected_data.job.require_education;
    e.experience.value = this.selected_data.job.require_year_experience;
    e.job_location.value = this.selected_data.job.job_location;
    e.postal_code.value = this.selected_data.job.zip_code;
    e.comment.value = this.selected_data.job.comment;
    e.department.value = this.selected_data.job.department;
    e.working_hour.value = this.selected_data.working_hours;
    e.job_type.value = this.selected_data.job.job_type;
    e.SDLC_Process.value = this.selected_data.job.SDLC_Process;
    e.domain.value = this.selected_data.job.domain;
    e.job_mode.defaultValue = this.selected_data.job.job_mode;
    e.interview_mode.value = this.selected_data.job.interview_mode;
    e.Job_duration_type.value =
      this.selected_data.job.project_duration.split(" ")[1];
    e.job_duration.value =
      this.selected_data.job.project_duration.split(" ")[0];
  };
}

// setPocName(props.data.job.poc_name);
