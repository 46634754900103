import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { onUpdateSelectedPartnerTaxList } from "../../feature/store/ClientReduxStore";
import { useDispatch } from "react-redux";
import {
  JobtableForbillrateContainer,
  JobtableForbillrateinput,
  JobtableForbillratetd,
  TableTd,
} from "../../style/table.styled";
import SelectMultiple from "../CommonComponet/SelectMultiple";
import SelectTextFields from "../CommonComponet/SelectTextFields";
import {
  FlexContainerForFilter,
  SwitchWithTitle,
} from "../../style/common.style";
import CustomSwitch from "../CommonComponet/CustomSwitch";

export default function PayRateSectionTileForJob(props) {
  const dispatch = useDispatch();
  const [selectedRecruiterList, setSelectedRecruiterList] = useState([]);
  const [selectedIdList, setSelectedIdList] = useState([]);
  const recruiterList = useSelector(
    (state) => state.JobReduxStore.temp_recruiter_list
  );
  const { taxData } = props;

  useEffect(() => {
    onPreFieldData();
  }, []);

  function onPreFieldData() {
    if (props.selectedData?.Recruiter_list === undefined) return;
    updateValue("_is_multiple", props.selectedData?._is_multiple === 1);
    props.selectedData?.Recruiter_list.map((data) =>
      selectedRecruiterList.push(data?.recruiter?.name)
    );
    props.selectedData?.Recruiter_list.map((data) =>
      selectedIdList.push(data?._id)
    );
    updateValue("recruiter_list", JSON.stringify(selectedIdList));
  }
  function updateValue(key, value) {
    var tmpData;
    tmpData = { [key]: value };
    dispatch(
      onUpdateSelectedPartnerTaxList({
        id: taxData.partner_id,
        data: tmpData,
        client: props.data,
      })
    );
  }

  function getRecruiterData() {
    if (taxData != null && taxData.recruter_id != null) {
      const filtered = recruiterList.filter((itemX) => {
        return itemX._id === taxData.recruter_id;
      });
      return filtered[0];
    }
  }
  const handleChange = (event, data) => {
    const { checked } = event.target;
    if (checked) {
      selectedIdList.push(data?._id);
      selectedRecruiterList.push(data?.name);
      let newData = selectedRecruiterList.filter(
        (item, index) => selectedRecruiterList.indexOf(item) === index
      );
      setSelectedRecruiterList(newData);
      let selectedRecruiterId = selectedIdList.filter(
        (item, index) => selectedIdList.indexOf(item) === index
      );
      updateValue("recruiter_list", JSON.stringify(selectedRecruiterId));
      setSelectedIdList(selectedRecruiterId);
      return;
    }
    let newData = selectedRecruiterList.filter(function (singleData) {
      return singleData !== data?.name;
    });
    let selectedRecruiterId = selectedIdList.filter(function (data) {
      return data !== data?._id;
    });
    updateValue("recruiter_list", JSON.stringify(selectedRecruiterId));
    setSelectedIdList(selectedRecruiterId);
    setSelectedRecruiterList(newData);
  };
  // function onSearch(e) {
  //   setSearchText(e);
  //   if (e.length !== 0) {
  //     const result = recruiterList.filter((data) =>
  //       data?.name.toLowerCase().trim().includes(e.toLowerCase())
  //     );
  //     setSearchResult(result);
  //   }
  // }

  return taxData == null ? (
    <div />
  ) : (
    <tr key={1}>
      <TableTd textAlignLeft={true}>
        <FlexContainerForFilter>
          <p
            style={{
              // marginLeft: 5,
              width: "40%",
            }}
          >
            {props.data.name.split("(").reverse()[0].replace(")", "")}
          </p>
          {/* <SwitchWithTitle style={{ marginTop: 0 }}>
            <CustomSwitch
              checked={
                taxData?._is_multiple || props.selectedData?._is_multiple === 1
              }
              onChange={(e) => updateValue("_is_multiple", e.target.checked)}
            />
            {"\xa0\xa0\xa0:\xa0Is Multiple"}
          </SwitchWithTitle> */}
        </FlexContainerForFilter>
        {/* {taxData._is_multiple ? (
          <SelectMultiple
            selectedRecruiterList={selectedRecruiterList}
            handleChange={handleChange}
            list={recruiterList}
          />
        ) : recruiterList.length === 0 ? (
          <div />
        ) : ( */}
        <div className="recruiterSelect">
          <SelectTextFields
            defaultValue={getRecruiterData()}
            placeholder="Recruiter"
            value={getRecruiterData()}
            onChange={(value) => {
              updateValue("_is_multiple", false);
              updateValue("recruter_id", value ? value?._id : "");
            }}
            options={recruiterList}
            getOptionLabel={(option) => (option === null ? "" : option.name)}
            onBlur={() => {}}
          />
        </div>
        {/* )} */}
      </TableTd>
      <JobtableForbillratetd>
        <JobtableForbillrateContainer>
          <JobtableForbillrateinput
            className="icon"
            type="number"
            value={taxData._1099}
            onBlur={() => {}}
            onChange={(e) => {
              updateValue("_1099", e.target.value);
            }}
            // placeholder="0"
            name="1099"
          />
          <span>
            | {props.data.is_in_percentage === 0 ? "$" : ""}
            {parseInt(props.data._1099) + parseInt(props.data.margin)}
            {props.data.is_in_percentage === 0 ? "" : "%"}
          </span>
        </JobtableForbillrateContainer>
      </JobtableForbillratetd>
      <JobtableForbillratetd>
        <JobtableForbillrateContainer>
          <JobtableForbillrateinput
            type="number"
            className="icon"
            value={taxData.w2_without_benifit}
            onBlur={() => null}
            onChange={(e) => {
              updateValue("w2_without_benifit", e.target.value);
            }}
            name="W2 without Benifits"
          />
          <span> | {props.data.w2_without_benifit}</span>
        </JobtableForbillrateContainer>
      </JobtableForbillratetd>
      <JobtableForbillratetd>
        <JobtableForbillrateContainer>
          <JobtableForbillrateinput
            type="number"
            className="icon"
            value={taxData.w2_with_benifits}
            onBlur={() => null}
            onChange={(e) => {
              updateValue("w2_with_benifits", e.target.value);
            }}
            name="W2 with Benifits"
          />
          <span> | {props.data.w2_with_benifit}</span>
        </JobtableForbillrateContainer>
      </JobtableForbillratetd>
      <JobtableForbillratetd>
        <JobtableForbillrateContainer>
          <JobtableForbillrateinput
            type="number"
            className="icon"
            value={taxData.w2_salarized}
            onChange={(e) => {
              updateValue("w2_salarized", e.target.value);
            }}
            onBlur={() => null}
            name="W2 Salarized"
          />
          <span> | {props.data.w2_salarized}</span>
        </JobtableForbillrateContainer>
      </JobtableForbillratetd>
    </tr>
  );
}
