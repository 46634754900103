import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useSelector, useDispatch } from "react-redux";
import { changeSelectedRecruiterId } from "../../feature/store/JobReduxStore";
import { CommonIcon } from "../../Utils/CommonIcon";
import { FilterContainer, FilterSpan } from "../../style/common.style";
import { FilterIcon } from "../../style/Icon.style";
import { CommonStringForLocalStorage } from "../../Utils/CommonString";
import { useEffect } from "react";
import ApplicantContoller from "../../controller/ApplicantContoller";
import { CommonColor } from "../../Utils/color";

export default function FilterDropDown2(props) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [allUser, setallUser] = React.useState([]);
  const temp_recruiter_list = useSelector(
    (state) => state.JobReduxStore.temp_recruiter_list
  );
  const selected_recruiter_id = useSelector(
    (state) => state.JobReduxStore.selected_recruiter_id
  );
  const applicantController = new ApplicantContoller();
  useEffect(() => {
    var allObj = {
      name: "all",
      _id: "123",
    };
    setallUser([allObj, ...temp_recruiter_list]);
    if (props.isVisible) {
      var user = JSON.parse(
        localStorage.getItem(CommonStringForLocalStorage.user)
      );
      dispatch(changeSelectedRecruiterId(user.user._id));
    }
  }, [temp_recruiter_list]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    setAnchorEl(null);
    dispatch(changeSelectedRecruiterId(e));
    applicantController.getJobDataList(1, e);
  };

  function getRecruiterName() {
    if (selected_recruiter_id === "123") {
      return "(All)";
    }
    const filteredData = temp_recruiter_list.filter(
      (item) => item._id === selected_recruiter_id
    );
    if (filteredData[0] !== undefined) {
      return (
        "(" +
        filteredData[0].name.substring(0, filteredData[0].name.indexOf(" ")) +
        ")"
      );
    }
  }

  function getIsActive(status) {
    if (status === selected_recruiter_id) return true;
    return false;
  }
  return (
    <>
      <FilterContainer onClick={handleClick}>
        <FilterSpan isVisible={props.isVisible}>Recruiter</FilterSpan>
        {props.isVisible ? (
          ""
        ) : (
          <>
            {/* {getRecruiterName()} */}
            <FilterIcon icon={CommonIcon.down_arrow} />
          </>
        )}
      </FilterContainer>
      <Menu
        className="recruiterDropDown"
        {...props}
        style={{
          border: "none",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={(e) => handleClose(selected_recruiter_id)}
      >
        {allUser.map((element, index) => (
          <MenuItem
            key={index}
            style={{
              color: getIsActive(element._id)
                ? CommonColor.primary_color
                : CommonColor.grey,
              fontWeight: getIsActive(element._id) ? "600" : "500",
              textTransform: "capitalize",
            }}
            onClick={() => handleClose(element._id)}
          >
            {element.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
