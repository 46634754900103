import FilterDropDown2 from "../component/CommonComponet/FilterDropDown2";
import DateFilter from "../component/CommonComponet/jobComponent/DateFilter";
import JobStatusFilter from "../component/CommonComponet/jobComponent/JobStatusFilter";
import PartnerFilterDropDown from "../component/CommonComponet/PartnerFilterDropDown";

//URLs
export var BASE_URL = "https://api.prexelite.com/";
export const vapidKey =
  "BIron_Dfa0QQ3GsAojmxIoCJhdEcb1BEOOkvqtWlSo_wAThaV-in3VsWnj3PmUU8aQ7TSu1dS73elzoxPP4gMC0";
// export var BASE_URL = "https://d9e9-2405-201-2028-d817-c9a2-aea9-6253-dea0.in.ngrok.io/";
// export var BASE_URL = "http://144.91.98.236:3002/";
// export var BASE_URL = "https://prexel-ats-api.herokuapp.com/";
export var RESUME_BASEURL = BASE_URL + "resume/";
export var PROFILE_BASEURL = BASE_URL + "profile/";
export var ATTACHMENT_BASEURL = BASE_URL + "attachment/";
//pagination
export var TOTAL_NUMBER = "6";
export var BLANK_TEXT_VALIDATION = "abcdefghijklmnopqrstuvwxyz090";
// Theme Colors
export var PRIMARY_COLOR = "#142130v";
//Constant for ATS
export var SOURCE_BY = [
  "Monster",
  "Careerbuilder",
  "Dice",
  "TechFetch",
  "ZipRecruiter",
  "indeed",
  "Glassdoor",
  "Resume-library",
  "Snagajob",
  "ZipRecruite",
  "LinkedIn",
  "Others",
];
export var TEXT_TERM = [
  "1099",
  "C2C",
  "W2 without Benifits",
  "W2 with Benifits",
  "W2 Salarized",
  "Others",
];

export var TEXT_TERM_OBJ = {
  _1099: "1099",
  C2C: "C2C",
  W2_without_Benifits: "W2 without Benifits",
  W2_with_Benifits: "W2 with Benifits",
  W2_Salarized: "W2 Salarized",
  Others: "Others",
};

export var WORK_AUTHORITY = [
  "US Citizen",
  "GC (PR)",
  "GC (EAD)",
  "H-1B Visa ",
  "H-4 (EAD)",
  "CPT (EAD)",
  "OPT (EAD)",
  "TN Visa",
  "L Visa",
  "OTHERS",
];
export var TOTAL_EXPERIENCE = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  ">30",
];
export var JOB_STATUS = ["OPEN", "CLOSED", "ON HOLD", "IGNORED", "COVERED"];
export var JOB_STATUS_WITH_KEYS = {
  OPEN: "OPEN",
  CLOSED: "CLOSED",
  ON_HOLD: "ON HOLD",
  IGNORED: "IGNORED",
  COVERED: "COVERED",
};
export var SUBMISSION_STATUS_FOR_MANAGER = [
  "IN TOUCH",
  "LOCKED",
  "REVIEW",
  "PLEASE SUBMIT",
  "SUBMISSION",
  "HOLD INTERNALLY",
  "REJECTED INTERNALLY",
  "REJECTED BY CLIENT",
  "INTERVIEW",
  "IV NO SHOW",
  "OFFER",
  "PLACEMENT",
  "DECLINED/BD BY CLIENT",
  "DECLINED/BD BY CONSULTANT",
  "PROJECT END",
];
export var SUBMISSION_STATUS_IN_TOUCH = ["IN TOUCH", "LOCKED"];

export var SUBMISSION_STATUS = {
  IN_TOUCH: "IN TOUCH",
  LOCKED: "LOCKED",
  REVIEW: "REVIEW",
  SUBMISSION: "SUBMISSION",
  INTERVIEW: "INTERVIEW",
  INTERVIEW_NO_SHOW: "IV NO SHOW",
  OFFER: "OFFER",
  PLACEMENT: "PLACEMENT",
  DECLINED_BY_CLIENT: "DECLINED/BD BY CLIENT",
  DECLINED_BY_CONSULTANT: "DECLINED/BD BY CONSULTANT",
  PROJECT_COMPLETED: "PROJECT END",
  TERMINATED: "TERMINATED",
  RESIGNED: "RESIGNED",
};
export var SUBMISSION_STATUS_FOR_RECRUITER = [
  "IN TOUCH",
  "LOCKED",
  "REVIEW",
  "PLEASE SUBMIT",
  "SUBMISSION",
  "INTERVIEW",
];
export var STATUS_FOR_ADDITIONAL_INFO = [
  "INTERVIEW",
  "OFFER",
  "LOCKED",
  "PLACEMENT",
  "PROJECT END",
  SUBMISSION_STATUS.RESIGNED,
  SUBMISSION_STATUS.TERMINATED,
  SUBMISSION_STATUS.DECLINED_BY_CONSULTANT,
  SUBMISSION_STATUS.DECLINED_BY_CLIENT,
];
export var JobStatusArray = [
  "ALL",
  "OPEN",
  "CLOSED",
  "ON HOLD",
  "IGNORED",
  "COVERED",
];
export var dueDateShortBy = ["ASC", "DESC"];

export var MONTHS = [
  "January",
  "Febuary",
  "March",
  "April",
  "May",
  "June",
  "july",
  "August",
  "September",
  "October",
  "Novermber",
  "December",
];
export var LEVEL_TH = [
  {
    name: "No.",
    align: "center",
  },
  {
    name: "Level",
    align: "left",
  },
  {
    name: "Designation",
    align: "left",
  },
  {
    name: "",
    align: "center",
  },
];
export var DOMAIN_TH = ["Industry", "Role Categories", "Role Sub Categories"];
export var RECRUITER_TH = [
  {
    name: "No.",
    align: "center",
  },
  {
    name: "Name",
    align: "left",
  },
  {
    name: "Email",
    align: "left",
  },
  {
    name: "Prexelite ID",
    align: "left",
  },
  {
    name: "Phone no.",
    align: "left",
  },
  {
    name: "Recruiter",
    align: "center",
  },
  {
    name: "",
    align: "center",
  },
];
export var CLIENT_TH = [
  {
    name: "No.",
    align: "center",
  },
  {
    name: "Client name",
    align: "left",
  },
  {
    name: "Partner name",
    align: "left",
  },
  {
    name: "Status",
    align: "center",
  },
  {
    name: "",
    align: "center",
  },
];
export var PARTNER_TH = [
  {
    name: "No.",
    align: "center",
  },
  {
    name: "Partner Name",
    align: "left",
  },
  {
    name: "POC Name",
    align: "left",
  },
  {
    name: "Email",
    align: "left",
  },
  {
    name: "Phone no.",
    align: "left",
  },
  {
    name: "Status",
    align: "center",
  },
  {
    name: "",
    align: "center",
  },
];
export var TAXTERM_TH = [
  "Partner and Assigned Recruiter",
  "Margin",
  "1099 %",
  "W2 wo B %",
  "W2 w B %",
  "W2 Sal %",
];
export var SUBMISSION_TH = [
  "No.",
  "Applicant",
  "Position title",
  "Department",
  "Partner",
];
export var JOB_TH = [
  {
    name: "Status",
    align: "center",
    is_visible: true,
  },
  {
    name: "Due Date",
    align: "left",
    is_visible: true,
  },
  {
    name: "Job Title",
    align: "left",
    is_visible: true,
  },
  {
    name: "Job ID",
    align: "left",
    is_visible: true,
  },
  {
    name: "Client",
    align: "left",
    is_visible: true,
  },
  {
    name: "Max Sub",
    align: "center",
    is_visible: true,
  },
  {
    name: "Sub Made",
    align: "center",
    is_visible: true,
  },
  {
    name: "Partner",
    align: "left",
    is_visible: true,
  },
  {
    name: "Recruiter",
    align: "left",
    is_visible: true,
  },
  {
    name: "",
    align: "center",
    is_visible: true,
  },
];
export var JOB_TH_FOR_RECRUITER = [
  {
    name: <JobStatusFilter />,
    align: "center",
  },
  {
    name: <DateFilter />,
    align: "left",
  },
  {
    name: "Job Title",
    align: "left",
  },
  {
    name: "Job ID",
    align: "left",
  },
  {
    name: "Client",
    align: "left",
  },
  {
    name: "Max Sub",
    align: "center",
  },
  {
    name: "Sub Made",
    align: "center",
  },
  {
    name: <PartnerFilterDropDown />,
    align: "left",
  },
  {
    name: "",
    align: "center",
  },
];
export var JOB_TH_FOR_DASHBOARD = [
  {
    name: "Due Date",
    align: "left",
  },
  {
    name: "Job Title",
    align: "left",
  },
  {
    name: "Job ID",
    align: "left",
  },
  {
    name: "Client",
    align: "left",
  },
  {
    name: "Max Sub",
    align: "center",
  },
  {
    name: "Sub Made",
    align: "center",
  },
  {
    name: "Partner",
    align: "left",
  },
];
export var JOB_FOR_APPLICANT_TH = [
  "",
  "Position ID",
  "Partner",
  // <PartnerFilterDropDown />,
  "Client",
  // "Department",
  "Job Title",
  // "Job Location",
  "No of Sub.",
  "Max Sub.",
  <DateFilter />,
  <FilterDropDown2 />,
  "",
];
export var TAX_TERM_TABLE_FOR_JOB = [
  "Rates",
  "Pay Rate",
  // "Pre",
  // "Custom ( $ / % )",
  "C Rate",
];
export var INTERVIEW_MODE = ["Video", "Telephonic", "In person"];
export var DATE_FORMATE = "MM/DD/YYYY";
export var TIME_FORMATE = "hh:mm a";
export var LONG_DATE_FORMATE = "MMMM Do YYYY";
export var LONG_DATE_WITH_TIME = "MMMM Do YYYY, hh:mm A";
export var BOX_SHADOW =
  "0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 2px 5px 0 rgba(0, 0, 0, 0.1)";
export var VERTICALMENU_OPTION = ["View", "Edit", "Delete"];
export var VERTICALMENU_OPTION_WITHOUT_DELETE = ["View", "Edit"];
export var VERTICAL_MENU_OPTION_WITHOUT_UPDATE = ["View", "Delete"];
export var ROUND_LOADER_TYPE = "bubble-scale";
export var SPINNER_LOADER_TYPE = "spinner-cub";
export var JOB_TYPE = ["Remote", "On Site", "Hybrid"];
export var Report_for = ["Year", "Month", "Week"];
export var VERTICAL_MENU_OPTION_JOB = ["View", "Edit", "Delete", "Clone"];
export var VERTICAL_MENU_OPTION_WITHOUT_DELETE_JOB = ["View", "Edit", "Clone"];
export var TYPE = [
  "Year",
  "Month",
  "Week",
  "Day",
  "Custom",
  "Last 3 Month",
  "Last 6 Month",
];

export const DemoReport = [
  {
    name: "Taranjeet Kaur",
    SUBMISSION: 3,
    "REJECTED BY CLIENT": 0,
    INTERVIEW: 0,
    "INTERVIEW NO SHOW": 0,
    OFFER: 0,
    "DECLINED/BD BY CLIENT": 0,
    "DECLINED/BD BY CONSULTANT": 0,
    PLACEMENT: 0,
    TERMINATED: 0,
    RESIGNED: 0,
    "PROJECT END": 0,
  },
  {
    name: "Shazia hussain",
    SUBMISSION: 4,
    "REJECTED BY CLIENT": 0,
    INTERVIEW: 0,
    "INTERVIEW NO SHOW": 0,
    OFFER: 0,
    "DECLINED/BD BY CLIENT": 0,
    "DECLINED/BD BY CONSULTANT": 0,
    PLACEMENT: 0,
    TERMINATED: 0,
    RESIGNED: 0,
    "PROJECT END": 0,
  },
  {
    name: "Bhavini Agarwal",
    SUBMISSION: 0,
    "REJECTED BY CLIENT": 0,
    INTERVIEW: 0,
    "INTERVIEW NO SHOW": 0,
    OFFER: 0,
    "DECLINED/BD BY CLIENT": 0,
    "DECLINED/BD BY CONSULTANT": 0,
    PLACEMENT: 0,
    TERMINATED: 0,
    RESIGNED: 0,
    "PROJECT END": 0,
  },
  {
    name: "Abrar Shaikh",
    SUBMISSION: 19,
    "REJECTED BY CLIENT": 0,
    INTERVIEW: 0,
    "INTERVIEW NO SHOW": 0,
    OFFER: 0,
    "DECLINED/BD BY CLIENT": 0,
    "DECLINED/BD BY CONSULTANT": 0,
    PLACEMENT: 0,
    TERMINATED: 0,
    RESIGNED: 0,
    "PROJECT END": 0,
  },
  {
    name: "Vishal Mange",
    SUBMISSION: 1,
    "REJECTED BY CLIENT": 0,
    INTERVIEW: 0,
    "INTERVIEW NO SHOW": 0,
    OFFER: 0,
    "DECLINED/BD BY CLIENT": 0,
    "DECLINED/BD BY CONSULTANT": 0,
    PLACEMENT: 0,
    TERMINATED: 0,
    RESIGNED: 0,
    "PROJECT END": 0,
  },
  {
    name: "Abhishek Kabi ",
    SUBMISSION: 3,
    "REJECTED BY CLIENT": 0,
    INTERVIEW: 0,
    "INTERVIEW NO SHOW": 0,
    OFFER: 0,
    "DECLINED/BD BY CLIENT": 0,
    "DECLINED/BD BY CONSULTANT": 0,
    PLACEMENT: 0,
    TERMINATED: 0,
    RESIGNED: 0,
    "PROJECT END": 0,
  },
  {
    name: "Aaisha Gul",
    SUBMISSION: 2,
    "REJECTED BY CLIENT": 0,
    INTERVIEW: 0,
    "INTERVIEW NO SHOW": 0,
    OFFER: 0,
    "DECLINED/BD BY CLIENT": 0,
    "DECLINED/BD BY CONSULTANT": 0,
    PLACEMENT: 0,
    TERMINATED: 0,
    RESIGNED: 0,
    "PROJECT END": 0,
  },
  {
    name: "Karthik N",
    SUBMISSION: 2,
    "REJECTED BY CLIENT": 0,
    INTERVIEW: 0,
    "INTERVIEW NO SHOW": 0,
    OFFER: 0,
    "DECLINED/BD BY CLIENT": 0,
    "DECLINED/BD BY CONSULTANT": 0,
    PLACEMENT: 0,
    TERMINATED: 0,
    RESIGNED: 0,
    "PROJECT END": 0,
  },
  {
    name: "Sanket Mishra",
    SUBMISSION: 2,
    "REJECTED BY CLIENT": 0,
    INTERVIEW: 0,
    "INTERVIEW NO SHOW": 0,
    OFFER: 0,
    "DECLINED/BD BY CLIENT": 0,
    "DECLINED/BD BY CONSULTANT": 0,
    PLACEMENT: 0,
    TERMINATED: 0,
    RESIGNED: 0,
    "PROJECT END": 0,
  },
  {
    name: "Deepak Kumar",
    SUBMISSION: 8,
    "REJECTED BY CLIENT": 0,
    INTERVIEW: 0,
    "INTERVIEW NO SHOW": 0,
    OFFER: 0,
    "DECLINED/BD BY CLIENT": 0,
    "DECLINED/BD BY CONSULTANT": 0,
    PLACEMENT: 0,
    TERMINATED: 0,
    RESIGNED: 0,
    "PROJECT END": 0,
  },
  {
    name: "Aastha Patil ",
    SUBMISSION: 5,
    "REJECTED BY CLIENT": 0,
    INTERVIEW: 2,
    "INTERVIEW NO SHOW": 0,
    OFFER: 0,
    "DECLINED/BD BY CLIENT": 0,
    "DECLINED/BD BY CONSULTANT": 0,
    PLACEMENT: 0,
    TERMINATED: 0,
    RESIGNED: 0,
    "PROJECT END": 0,
  },
  {
    name: "Sourav Das",
    SUBMISSION: 7,
    "REJECTED BY CLIENT": 0,
    INTERVIEW: 0,
    "INTERVIEW NO SHOW": 0,
    OFFER: 0,
    "DECLINED/BD BY CLIENT": 0,
    "DECLINED/BD BY CONSULTANT": 0,
    PLACEMENT: 0,
    TERMINATED: 0,
    RESIGNED: 0,
    "PROJECT END": 0,
  },
  {
    name: "Kiran Parmar",
    SUBMISSION: 0,
    "REJECTED BY CLIENT": 0,
    INTERVIEW: 0,
    "INTERVIEW NO SHOW": 0,
    OFFER: 0,
    "DECLINED/BD BY CLIENT": 0,
    "DECLINED/BD BY CONSULTANT": 0,
    PLACEMENT: 0,
    TERMINATED: 0,
    RESIGNED: 0,
    "PROJECT END": 0,
  },
  {
    name: "Himanshi Luhach",
    SUBMISSION: 2,
    "REJECTED BY CLIENT": 0,
    INTERVIEW: 0,
    "INTERVIEW NO SHOW": 0,
    OFFER: 0,
    "DECLINED/BD BY CLIENT": 0,
    "DECLINED/BD BY CONSULTANT": 0,
    PLACEMENT: 0,
    TERMINATED: 0,
    RESIGNED: 0,
    "PROJECT END": 0,
  },
  {
    name: "Surbhi Chaudhary",
    SUBMISSION: 4,
    "REJECTED BY CLIENT": 0,
    INTERVIEW: 0,
    "INTERVIEW NO SHOW": 0,
    OFFER: 0,
    "DECLINED/BD BY CLIENT": 0,
    "DECLINED/BD BY CONSULTANT": 0,
    PLACEMENT: 0,
    TERMINATED: 1,
    RESIGNED: 0,
    "PROJECT END": 0,
  },
  {
    name: "Rishita Dash",
    SUBMISSION: 5,
    "REJECTED BY CLIENT": 0,
    INTERVIEW: 1,
    "INTERVIEW NO SHOW": 0,
    OFFER: 0,
    "DECLINED/BD BY CLIENT": 0,
    "DECLINED/BD BY CONSULTANT": 0,
    PLACEMENT: 1,
    TERMINATED: 0,
    RESIGNED: 0,
    "PROJECT END": 0,
  },
  {
    name: "Prem Pithiya",
    SUBMISSION: 0,
    "REJECTED BY CLIENT": 0,
    INTERVIEW: 0,
    "INTERVIEW NO SHOW": 0,
    OFFER: 0,
    "DECLINED/BD BY CLIENT": 0,
    "DECLINED/BD BY CONSULTANT": 0,
    PLACEMENT: 0,
    TERMINATED: 0,
    RESIGNED: 0,
    "PROJECT END": 0,
  },
  {
    name: "Banmali Barada",
    SUBMISSION: 9,
    "REJECTED BY CLIENT": 0,
    INTERVIEW: 0,
    "INTERVIEW NO SHOW": 0,
    OFFER: 0,
    "DECLINED/BD BY CLIENT": 0,
    "DECLINED/BD BY CONSULTANT": 0,
    PLACEMENT: 0,
    TERMINATED: 0,
    RESIGNED: 0,
    "PROJECT END": 0,
  },
  {
    name: "Jeet Jaison",
    SUBMISSION: 2,
    "REJECTED BY CLIENT": 0,
    INTERVIEW: 0,
    "INTERVIEW NO SHOW": 0,
    OFFER: 0,
    "DECLINED/BD BY CLIENT": 0,
    "DECLINED/BD BY CONSULTANT": 0,
    PLACEMENT: 0,
    TERMINATED: 0,
    RESIGNED: 0,
    "PROJECT END": 0,
  },
  {
    name: "Aafreen Malek ",
    SUBMISSION: 3,
    "REJECTED BY CLIENT": 0,
    INTERVIEW: 0,
    "INTERVIEW NO SHOW": 0,
    OFFER: 0,
    "DECLINED/BD BY CLIENT": 0,
    "DECLINED/BD BY CONSULTANT": 0,
    PLACEMENT: 0,
    TERMINATED: 0,
    RESIGNED: 0,
    "PROJECT END": 0,
  },
  {
    name: "Mahek Bulani",
    SUBMISSION: 0,
    "REJECTED BY CLIENT": 0,
    INTERVIEW: 0,
    "INTERVIEW NO SHOW": 0,
    OFFER: 0,
    "DECLINED/BD BY CLIENT": 0,
    "DECLINED/BD BY CONSULTANT": 0,
    PLACEMENT: 0,
    TERMINATED: 0,
    RESIGNED: 0,
    "PROJECT END": 0,
  },
  {
    name: "Aman Charaniya",
    SUBMISSION: 2,
    "REJECTED BY CLIENT": 0,
    INTERVIEW: 0,
    "INTERVIEW NO SHOW": 0,
    OFFER: 0,
    "DECLINED/BD BY CLIENT": 0,
    "DECLINED/BD BY CONSULTANT": 0,
    PLACEMENT: 0,
    TERMINATED: 0,
    RESIGNED: 0,
    "PROJECT END": 0,
  },
  {
    name: "Deepal Nagar",
    SUBMISSION: 1,
    "REJECTED BY CLIENT": 0,
    INTERVIEW: 0,
    "INTERVIEW NO SHOW": 0,
    OFFER: 0,
    "DECLINED/BD BY CLIENT": 0,
    "DECLINED/BD BY CONSULTANT": 0,
    PLACEMENT: 0,
    TERMINATED: 1,
    RESIGNED: 0,
    "PROJECT END": 0,
  },
  {
    name: "Saurabh Singh Parihar",
    SUBMISSION: 8,
    "REJECTED BY CLIENT": 0,
    INTERVIEW: 0,
    "INTERVIEW NO SHOW": 0,
    OFFER: 0,
    "DECLINED/BD BY CLIENT": 0,
    "DECLINED/BD BY CONSULTANT": 0,
    PLACEMENT: 0,
    TERMINATED: 0,
    RESIGNED: 0,
    "PROJECT END": 0,
  },
  {
    name: "Darshil Vyas",
    SUBMISSION: 2,
    "REJECTED BY CLIENT": 0,
    INTERVIEW: 0,
    "INTERVIEW NO SHOW": 0,
    OFFER: 0,
    "DECLINED/BD BY CLIENT": 0,
    "DECLINED/BD BY CONSULTANT": 0,
    PLACEMENT: 0,
    TERMINATED: 0,
    RESIGNED: 0,
    "PROJECT END": 0,
  },
  {
    name: "Sonam Prajapati",
    SUBMISSION: 4,
    "REJECTED BY CLIENT": 0,
    INTERVIEW: 0,
    "INTERVIEW NO SHOW": 0,
    OFFER: 0,
    "DECLINED/BD BY CLIENT": 0,
    "DECLINED/BD BY CONSULTANT": 0,
    PLACEMENT: 1,
    TERMINATED: 0,
    RESIGNED: 0,
    "PROJECT END": 0,
  },
  {
    name: "Jolly Machchhar",
    SUBMISSION: 8,
    "REJECTED BY CLIENT": 0,
    INTERVIEW: 0,
    "INTERVIEW NO SHOW": 0,
    OFFER: 0,
    "DECLINED/BD BY CLIENT": 0,
    "DECLINED/BD BY CONSULTANT": 0,
    PLACEMENT: 0,
    TERMINATED: 0,
    RESIGNED: 0,
    "PROJECT END": 0,
  },
  {
    name: "Preeti Luhach",
    SUBMISSION: 0,
    "REJECTED BY CLIENT": 0,
    INTERVIEW: 0,
    "INTERVIEW NO SHOW": 0,
    OFFER: 0,
    "DECLINED/BD BY CLIENT": 0,
    "DECLINED/BD BY CONSULTANT": 0,
    PLACEMENT: 0,
    TERMINATED: 0,
    RESIGNED: 0,
    "PROJECT END": 0,
  },
  {
    name: "Diane Niyomwungere",
    SUBMISSION: 1,
    "REJECTED BY CLIENT": 0,
    INTERVIEW: 0,
    "INTERVIEW NO SHOW": 0,
    OFFER: 0,
    "DECLINED/BD BY CLIENT": 0,
    "DECLINED/BD BY CONSULTANT": 0,
    PLACEMENT: 0,
    TERMINATED: 0,
    RESIGNED: 0,
    "PROJECT END": 0,
  },
];

export const submissionStatuses = [
  // "NAME",
  "SUBMISSION",
  // "REJECTED BY CLIENT",
  "INTERVIEW",
  // "INTERVIEW NO SHOW",
  "OFFER",
  // "DECLINED/BD BY CLIENT",
  // "DECLINED/BD BY CONSULTANT",
  "PLACEMENT",
  // "TERMINATED",
  // "RESIGNED",
  "PROJECT END",
];
export const report_card_list_with_value = {
  all: false,
  job: true,
  applicant: false,
  in_touch: false,
  locked: false,
  review: false,
  please_submit: false,
  submission: true,
  hold_internally: false,
  rejected_internally: false,
  rejected_by_client: false,
  interview: true,
  iv_no_show: false,
  offer: true,
  placement: true,
  bd_by_client: false,
  bd_by_consultant: false,
  project_end: true,
};
export const report_card_list_without_value = [
  "all",
  "job",
  "submission",
  "interview",
  "offer",
  "placement",
  "project_end",
];
export const report_card_list = [
  "all",
  "job",
  "applicant",
  "in_touch",
  "locked",
  "review",
  "please_submit",
  "submission",
  "hold_internally",
  "rejected_internally",
  "rejected_by_client",
  "interview",
  "iv_no_show",
  "offer",
  "placement",
  "bd_by_client",
  "bd_by_consultant",
  "project_end",
];
export const all_report_card_list_with_value = {
  all: true,
  job: true,
  applicant: true,
  in_touch: true,
  locked: true,
  review: true,
  please_submit: true,
  submission: true,
  hold_internally: true,
  rejected_internally: true,
  rejected_by_client: true,
  interview: true,
  iv_no_show: true,
  offer: true,
  placement: true,
  bd_by_client: true,
  bd_by_consultant: true,
  project_end: true,
};
export const time_zone_list = ["EST", "CST", "PST", "MST"];
