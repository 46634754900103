import React, { useEffect, useState } from "react";
import {
  CustomTableContainer,
  TableTbody,
  TableTd,
  TableTh,
  Table,
  TableRow,
  TableName,
  DashboardTableContainer,
} from "../../../style/table.styled";
import { DATE_FORMATE, SUBMISSION_TH } from "../../../Utils/constant";
import {
  ADD_INTERVIEW_DETAIL,
  INTERVIEW_DETAILS,
  VIEW_SUBMISSION,
} from "../../../Utils/enum";
import { CommonColor } from "../../../Utils/color";
import moment from "moment";
import {
  getClientName,
  getFormTitleForSubmission,
  onCheckLevelAccessibilityForDashboard,
} from "../../../Utils/utilitiFunction";
import SubmissionViewDetails from "../../ViewDetails/SubmissionViewDetails";
import BaseModal from "../../Modal/BaseModal";
import { FlexContainer } from "../../../style/common.style";
import InterviewDetailView from "../../CommonComponet/IntwerviewDetail/InterviewDetailView";
import InterviewStatus from "../../CommonComponet/IntwerviewDetail/interviewStatus";
import { SubmissionController } from "../../../controller/SubmissionController";
import { formatName } from "../../../Utils/validation";
import {
  CommonString,
  CommonStringForLocalStorage,
} from "../../../Utils/CommonString";
import { changeSelectedSubmission } from "../../../feature/store/SubmissionReduxStore";
import { useDispatch, useSelector } from "react-redux";
import CustomizedTooltip from "../../CommonComponet/CustomizedTooltip";
import OrderFilter from "../../CommonComponet/OrderFilter";

export default function SubmissionTableForDashboard(props) {
  const dispatch = useDispatch();
  const submissionController = new SubmissionController();
  const [submissionData, setSubmissionData] = useState([]);
  const [isopenModal, setisOpenModal] = useState(false);
  const [selectedData, setselectedData] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [modalStatus, setModalStatus] = useState();
  const [selectedOrder, setSelectedOrder] = React.useState("ASC");
  const submission_status = useSelector(
    (state) => state.SubmissionReduxStore.submission_status
  );
  useEffect(() => {
    getsubmissionList();
  }, [submission_status, selectedOrder]);
  async function getAscData(data) {
    if (selectedOrder === "DESC") {
      var AscendingData = await data
        .sort((a, b) => {
          return (
            new Date(a?.childJob?.due_date) - new Date(b?.childJob?.due_date)
          );
        })
        .reverse();
      return AscendingData;
    }
    var descData = await data.sort((a, b) => {
      return new Date(a?.childJob?.due_date) - new Date(b?.childJob?.due_date);
    });
    return descData;
  }
  async function getsubmissionList() {
    try {
      var status = {
        status: props.submissionStatus,
      };
      var res = await submissionController.getSubmissionsByStatus(status);
      var data = await getAscData(res);
      if (!onCheckLevelAccessibilityForDashboard()) {
        setSubmissionData(data);
        return;
      }
      var comment = JSON.parse(
        localStorage.getItem(CommonStringForLocalStorage.user)
      );
      var filteredData = data.filter(
        (singleData) => singleData.recruiter._id === comment.user._id
      );
      setSubmissionData(filteredData);
      return;
    } catch (error) {
      console.log(error);
    }
  }
  function onViewSubmission(modalSt, data) {
    setisOpenModal(true);
    setModalStatus(modalSt);
    setselectedData(data);
    dispatch(changeSelectedSubmission(data));
  }
  function getColor(e) {
    var inActiveColor = {
      background: CommonColor.white,
      color: CommonColor.primary_color,
    };
    if (e.interviewData === undefined) {
      return inActiveColor;
    }
    if (e.interviewData.data !== undefined) {
      var interViewDate = moment(e.interviewData.data.date).format(
        DATE_FORMATE
      );
      var currentDate = moment().format(DATE_FORMATE);
      if (interViewDate === currentDate) {
        var color = {
          background: CommonColor.bg_yellow,
          color: CommonColor.primary_color,
        };
        return color;
      }
    }
    return inActiveColor;
  }
  function onGetManualRefreshWithSubmissionId() {
    getsubmissionList();
    // console.log("i am called with id", wantToRemoveSubmissionId);
    // var newSubmissionData = submissionData.filter(function (item) {
    //   return item._id !== wantToRemoveSubmissionId;
    // });
    // console.log("hello", newSubmissionData);

    // setSubmissionData(newSubmissionData);
  }
  return submissionData.length == 0 ? (
    <></>
  ) : (
    <DashboardTableContainer>
      <CustomTableContainer height={"auto"}>
        <BaseModal
          isLoading={isLoading}
          isBig={modalStatus === INTERVIEW_DETAILS ? false : true}
          open={isopenModal}
          formTitle={
            modalStatus === INTERVIEW_DETAILS
              ? INTERVIEW_DETAILS
              : getFormTitleForSubmission(selectedData)
          }
          data={selectedData}
          isSubmissionView={true}
          submissionList={props.submissionList}
          handleClose={() => setisOpenModal(!isopenModal)}
        >
          {modalStatus === INTERVIEW_DETAILS ? (
            <InterviewStatus
              handleClose={() => setisOpenModal(!isopenModal)}
              isLoading={(e) => setisLoading(e)}
              modalStatus={modalStatus}
              selectedData={selectedData}
            />
          ) : (
            <SubmissionViewDetails
              handleClose={() => {
                onGetManualRefreshWithSubmissionId();
                setisOpenModal(!isopenModal);
              }}
              onClickEdit={(e) => onViewSubmission(ADD_INTERVIEW_DETAIL, e)}
              isLoading={(e) => setisLoading(e)}
              onRefresh={() => props.onRefresh()}
              data={selectedData}
            />
          )}
        </BaseModal>
        <TableName>{formatName(props.submissionStatus)}</TableName>
        <Table>
          <TableTbody>
            <TableRow>
              <TableTh textAlignLeft="left">Name</TableTh>
              <TableTh>Title</TableTh>
              <TableTh>ID</TableTh>
              <TableTh>Partner</TableTh>
              <TableTh>Client</TableTh>
              <TableTh>Recruiter</TableTh>
              <TableTh style={{ whiteSpace: "nowrap" }}>
                <OrderFilter
                  selectedOrder={selectedOrder}
                  getValue={(e) => setSelectedOrder(e)}
                  name="Due Date"
                />
                {/* Due Date */}
              </TableTh>
              <TableTh style={{ whiteSpace: "nowrap" }}>Updated At</TableTh>
              <TableTh textAlignLeft="left">Status</TableTh>
            </TableRow>
            {submissionData.map((singleData, index) => {
              const { client, parentJob, partner, recruiter } = singleData;
              if (singleData.status === props.submissionStatus)
                return (
                  <TableRow
                    style={{
                      background: getColor(singleData).background,
                      color: getColor(singleData).color,
                    }}
                    isActiveDate={
                      getColor(singleData).background === CommonColor.bg_yellow
                    }
                    key={index}
                    onClick={() =>
                      window.innerWidth < 500
                        ? onViewSubmission(VIEW_SUBMISSION, singleData)
                        : null
                    }
                    onDoubleClick={() =>
                      onViewSubmission(VIEW_SUBMISSION, singleData)
                    }
                  >
                    <TableTd
                      textAlignLeft="left"
                      ListLength={SUBMISSION_TH.length}
                    >
                      {formatName(singleData?.full_name)}
                    </TableTd>
                    <TableTd ListLength={SUBMISSION_TH.length}>
                      <CustomizedTooltip
                        name={parentJob?.position_title}
                        title={parentJob?.position_title}
                      />
                    </TableTd>
                    <TableTd ListLength={SUBMISSION_TH.length}>
                      {parentJob?.client_position_id === ""
                        ? "NA"
                        : parentJob?.client_position_id}
                    </TableTd>
                    <TableTd ListLength={SUBMISSION_TH.length}>
                      {getClientName(partner?.name)}
                    </TableTd>
                    <TableTd ListLength={SUBMISSION_TH.length}>
                      {getClientName(client?.name)}
                    </TableTd>
                    <TableTd ListLength={SUBMISSION_TH.length}>
                      {recruiter?.name?.split(" ")[0]}
                    </TableTd>
                    <TableTd
                      style={{ width: 90 }}
                      ListLength={SUBMISSION_TH.length}
                    >
                      {singleData?.childJob?.due_date === null
                        ? CommonString.open
                        : moment(singleData?.childJob?.due_date).format(
                            DATE_FORMATE
                          )}
                    </TableTd>
                    <TableTd
                      style={{ width: 90 }}
                      ListLength={SUBMISSION_TH.length}
                    >
                      {moment(singleData._updated_at).format(DATE_FORMATE)}
                    </TableTd>
                    <TableTd
                      textAlignLeft="left"
                      ListLength={SUBMISSION_TH.length}
                    >
                      {props.submissionStatus === "INTERVIEW" ? (
                        <FlexContainer align={"left"}>
                          <InterviewDetailView
                            onClickInterviewDetail={(e) =>
                              onViewSubmission(INTERVIEW_DETAILS, singleData)
                            }
                            data={singleData}
                          />
                        </FlexContainer>
                      ) : singleData.status ? (
                        singleData.status.toLowerCase()
                      ) : (
                        ""
                      )}
                    </TableTd>
                  </TableRow>
                );
            })}
          </TableTbody>
        </Table>
        {/* {getVisibility() ? <p>No data</p> : <></>} */}
        {/* <Container
        isLoading={isLoadingForSubmissionList === true}
        isVisible={
          filteredData !== undefined
            ? filteredData.length === 0
            : submissionData.length === 0
        }
      /> */}
      </CustomTableContainer>
    </DashboardTableContainer>
  );
}
